import { useState, useEffect } from "react";
import useInfoCuotas from "../../hooks/payments/useInfoCuotas";
import * as Api from "../../api";
import { Message } from "rsuite";
import PayQuantitiesList from "./PayQuantitiesList";
import Layout from "../../Components/Layout/Layout";
import CreditCard from "../../Components/Payment/CreditCard";
import LayoutAccount from "../../Components/Layout/LayoutAccount";
import { formatRut, formatDate, formatNumeric, getEdadCuota, getMonto } from "../../Service/Utils";

const PaymentsAuto = ({}) => {

	const [loading, setLoading] = useState(false);
	const { valor_cuota } = useInfoCuotas();
	const [inputs, setInputs] = useState({
		numero_cuotas: 0,
		authorized: 'disable'
	});
	const [errors, setErrors] = useState({});
	const [successMessage, setSuccessMessage] = useState('');
	const [enablePayment, setEnablePayment] = useState(false);

	const handleSubmit = async () => {

		setLoading(true);

		const res = await Api.autorizeAutomaticPayments(inputs);
		
		if (res.validations?.errors) {
			setErrors(res.validations?.errors);
		}

		if (res.status) {
			setErrors({});
			setSuccessMessage(res.data);
		}

		setLoading(false);
	}

	const handleInput = (e) => {
		setInputs({
		  ...inputs,
		  [e.target.name]: e.target.value,
		});
	}

	const getConfig = async () => {
		const res = await Api.getConfig();
		
		const authorized = res.data.enable === 1 ? 'enable' : 'disable';

		console.log(res.data.enable === 1);

		setInputs({
			['numero_cuotas']: res.data.cuotas,
		  	['authorized']: authorized
		});

		setEnablePayment(res.data.enable);

	}

	const handleAuthorized = (e) => {

		const enabled = e.target.value == 'enable' ? 'enable' : 'disable';

		setEnablePayment(e.target.value == 'enable' ? true : false );

		console.log(e.target.value);

		setInputs({
			...inputs,
			[e.target.name]: enabled,
		});

	}

	const onDelete = () => {
		window.location.reload(true);
	}

	useEffect(() => {
		getConfig();
	}, []);

	return (
		<LayoutAccount>
			<div className="row m-2">
				<div className="col-md-12">
					<h4>
						<strong>Pagos automáticos</strong>
					</h4>

					<Message showIcon type="success" header="Pagos automáticos">
						<p>Puedes realizar tus pagos mensuales de forma automatica sin la necesidad de ingresar al portal cada mes.</p>
					</Message>
					<br />

					<h5>Elegir cuotas a pagar</h5>

					<p>Valor cuota a pagar: ${ formatNumeric(valor_cuota) }</p>	

					<div className="row mb-3">
						<div className="col-12 col-md-5">
							<PayQuantitiesList 
									handleInput={handleInput} 
									titleSelect="Número de cuotas a pagar mensualmente" 
									defaultValue={inputs.numero_cuotas}
							/>
							<br />
							<span className="text-danger">{ errors?.numero_cuotas }</span>
						</div>
					</div>

					<h5>Método de pago</h5>

					<div className="row mb-3">
						<div className="col-12 col-md-5">
							<CreditCard onDelete={onDelete} />
						</div>
					</div>

					<div>
						<input type="radio" 
								id="authorize" 
								onChange={handleAuthorized} 
								name="authorized" 
								checked={enablePayment}
								value={'enable'} />

						<label htmlFor="authorize" className="m-2">Autorizar pago automatico.</label>
					</div>

					<div>

						<input type="radio" 
								id="unauthorize" 
								onChange={handleAuthorized} 
								name="authorized" 
								checked={!enablePayment} 
								value={'disable'} />

						<label htmlFor="unauthorize" className="m-2">Desactivar pago automatico.</label>
					</div>
					<p>
						<span className="text-danger">{ errors?.authorized }</span>
					</p>

					{
						successMessage && (
							<>
								<div className="alert alert-success">
									{successMessage}
								</div>
							</>
					)}


					<button className="btn btn-primary mb-3" onClick={handleSubmit} disabled={loading}>
						Guardar
						{ loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
					</button>


				</div>
			</div>
		</LayoutAccount>
	)
}

export default PaymentsAuto;