const Loader = ({}) => {
    
    return (
        <>
            <div className="spinner-border" role="status">
                <span className="sr-only"></span>
            </div>
        </>
    );
}

export default Loader;