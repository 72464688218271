import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'rsuite/dist/rsuite.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './index.css';
import './Assets/css/Main.scss';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';


const Loader = () => {
    return (
        <>
            Loading...
        </>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={<Loader />}>
                <App />
            </PersistGate>            
        </Provider>
    </React.StrictMode>
);
