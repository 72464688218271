import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Logo from "../../Components/Logo/Logo";
import * as Api from "../../api";
import { unformatRut } from "../../Service/Utils";
import { Col, Row, Spinner } from "react-bootstrap";
import LocalStorage from "./../../Service/LocalStorage";
import { Form, Schema, Message, toaster } from "rsuite";
import logo from "./../../Assets/Images/logotipo.svg";

const RecoverPassword = ({}) => {
	const history = useNavigate();

	const formRef = useRef();
	const [active, setActive] = useState(true);
	const [disabled, setDisabled] = useState(false);
	const [validated, setValidated] = useState(false);
	  const model = Schema.Model({
		rut: Schema.Types.StringType()
		  .isRequired("Este campo es obligatorio.")
		  .pattern(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "Rut no válido")
	});

	const TextField = ({ name, label, type, accepter, ...rest }) => (
	    <Form.Group controlId={name} className="mb-3">
	      <Form.ControlLabel>{label}</Form.ControlLabel>
	      <Form.Control name={name} accepter={accepter} {...rest} type={type} />
	    </Form.Group>
	);


	const handleSubmit = async () => {
	    if (!formRef.current.check()) {
	      toaster.push(
	        <Message type="error">
	          Existen campos que no cumplen con las validaciones
	        </Message>
	      );
	      return;
	    }
	    setActive(false);
	    setDisabled(true);
	    const params = {
	      rut: unformatRut(model.data.rut)
	    };
	    const data = await Api.sendRecoveryLink(params);
	    console.info(data);
	    if (data.validations !== undefined) {
	      toaster.push(
	        <Message type="error" duration="5000">{data.validations.errors["rut"][0]}</Message>
	      );
	      setDisabled(false);
	      setActive(true);
	      return;
	    }
	    if (data.status) {
	    	toaster.push(
	        	<Message type="success" duration="5000">{data.data}</Message>
	      	);
			setDisabled(false);
			setActive(true);
			return;	
	    } else {
	      toaster.push(<Message type="error" duration="5000">{data.errors}</Message>);
	      setDisabled(false);
	      setActive(true);
	    }
  };


	return (
		<>
			<div className="container-fluid log h-100 black">
				<div className="row justify-content-center align-items-center h-100">
					<div className="col-12 col-md-5 white h-100 d-flex flex-column align-items-center">

      					<Row className="mb-5">
      						<Col xs={12} sm={12} md={12}>
      							<img src={logo} alt="" width="100" className="mb-5"/>
      						</Col>
      					</Row>

						<Form model={model} ref={formRef} style={{ width: '100%'}}>
							<div className="row">
								<div className="col-md-12">
									<h6>Recuperar contraseña</h6>
								</div>
							</div>
							<br />
							<Row>
								<Col xs={12} sm={12} md={12}>
								  <TextField
								    name="rut"
								    type="rut"
								    label="Rut socio"
								    className="text-left"
								    placeholder="Ingrese rut de socio"
								  />
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={12} md={12} className="d-flex justify-content-center">
									{active ? (
										  <button
										    className="btn bg-red"
										    disabled={disabled}
										    onClick={handleSubmit}
										  >
										    Enviar email de recuperación
										  </button>
										) : (
										  <Spinner animation="grow" className="mt-3" />
										)}
								</Col>
								
							</Row>
							<br />
							<div className="row">
								<div className="col-md-12 text-center">
									<Link to="/login" className="">
				                    	Iniciar sesión
				                  	</Link>
								</div>
							</div>
		                </Form>

					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default RecoverPassword;