import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Credencial from "./Pages/Credencial/Credencial";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import MyProfile from "./Pages/MyProfile/MyProfile";
import Account from "./Pages/Account/Account";
import PaymentHistory from "./Pages/Account/PaymentHistory";
import PurchaseHistory from "./Pages/Account/PurchaseHistory";
import Normal from "./Pages/Pay/Normal";
import Regularizacion from "./Pages/Pay/Regularizacion";
import PaymentVoucher from "./Pages/PaymentVoucher/PaymentVoucher";
import Register from "./Pages/Register/Register";
import NoValidateEmail from "./Pages/Validate/NoValidateEmail";
import ValidateIncorporacion from "./Pages/Validate/ValidateIncorporacion";
import ValidateOne from "./Pages/Validate/ValidateOne";
import ValidateTwo from "./Pages/Validate/ValidateTwo";
import CardSubscriptionError from "./Pages/Cards/CardSubscriptionError";
import RecoverPassword from "./Pages/Auth/RecoverPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import PaymentsAuto from "./Pages/Pay/PaymentsAuto";
import Canjes from "./Pages/Canjes/Canjes";
import Checkout from "./Pages/Canjes/Checkout";
import CanjesVoucher from "./Pages/Canjes/CanjesVoucher";
import InviteNewPartner from "./Pages/Account/InviteNewPartner";

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/validation/first", element: <ValidateOne /> },
    { path: "/validation/second", element: <ValidateTwo /> },
    {
      path: "/validation/pago/incorporacion",	
      element: <ValidateIncorporacion />,
    },
    {
      path: "/email-validation",
      element: <NoValidateEmail />,
    },
    { path: "/register", element: <Register /> },
    // Password recovery
    { path: "/password/recover-password", element: <RecoverPassword /> },
    { path: "/password/reset-password", element: <ResetPassword /> },
    { path: "/account/payments/history", element: <PaymentHistory /> },
    { path: "/account/purchases/history", element: <PurchaseHistory /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/my-profile", element: <MyProfile /> },
    { path: "/my-account", element: <Account /> },
    { path: "/invite-new-partner", element: <InviteNewPartner /> },
    { path: "/canjes", element: <Canjes /> },
    { path: "/canjes/checkout", element: <Checkout /> },
    { path: "/canjes/voucher", element: <CanjesVoucher /> },
    { path: "/credencial", element: <Credencial /> },
    { path: "/pago/cuota-inscripcion", element: <Normal /> },
    { path: "/pago/cuota", element: <Normal /> },
    { path: "/pago/regularizacion", element: <Regularizacion /> },
    { path: "/pagos/automaticos", element: <PaymentsAuto /> },
    { path: "/payments/final", element: <PaymentVoucher /> },
    { path: "/cards/subscription/error", element: <CardSubscriptionError /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
