import React, { useState, useRef, forwardRef, useEffect } from 'react';
import {Row, Col} from 'react-bootstrap';
import { 
	List,
	Button,
	Modal,
	Message,
	Form,
	Input,
	Radio, 
	RadioGroup,
    Schema,
	SelectPicker,
    toaster,
    Panel,
    Loader,
    Placeholder
} from 'rsuite';
import LayoutCanjes from '../../Components/Layout/LayoutCanjes';
import { useDispatch, useSelector } from 'react-redux';
import usePoints from '../../hooks/account/usePoints';
import CartItem from './CartItem';
import regionsList from '../../static-data/regions.json';
import * as Api from "../../api";
import axios from 'axios';
import { formatNumeric } from '../../Service/Utils';
import pentacromImg from './../../Assets/Images/pentacrom.png';
import despachaloImg from './../../Assets/Images/despachalo.png';
import useProfile from '../../hooks/account/useProfile';
import { useNavigate } from 'react-router-dom';
import { cleanCart } from '../../store/canjes'

const dataStyle = {
    fontSize: '1.2em',
    fontWeight: 500
};

const Checkout = () => {

    const cart = useSelector((state) => state.cartReducer.products);
    const history = useNavigate();

    const [open, setOpen] = useState(false);
    const { points } = usePoints();

	const handleClose = () => {
		setOpen(false);
	};

	const Textarea = forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

	const [tipoEntrega, setTipoEntrega] = useState('retiro');

	const onRadio = (value) => {
		setTipoEntrega(value);
	}

	const [options_comuna, setoptions_comuna] = useState([]);
	const [region, setRegion] = useState(0);
	const [comuna, setComuna] = useState(0);

	const regionSelector = forwardRef((props, ref) => (
		<SelectPicker
		  cleanable={false}
		  data={regionsList['regiones']}
		  valueKey="value"
		  labelKey="name"
		  {...props}
		  ref={ref}
		/>
	));

	const comunaSelector = forwardRef((props, ref) => (
		<SelectPicker
		  defaultValue={comuna}
		  cleanable={true}
		  data={options_comuna}
		  valueKey="id"
		  labelKey="nombre"
		  {...props}
		  ref={ref}
		/>
	));

    const getComumunes = async (id) => {
        setComuna(0);
        const response = await Api.getCommunes(id);
        setoptions_comuna(response);
        setRegion(id);
    };

    const getTotalCanje = () => {

        let total = 0;

        cart.forEach ( item => {
            total += item.points * item.quantity;
        })

        return total;
    }

    const outOfCredit = () => {
        const remaining = points - getTotalCanje();
        console.log("out of credit: ", remaining, " poings: ", points, " total canje: ", getTotalCanje())
        return remaining < 0;
    }

    const getRemainingPoints = () => {
        const remaining = points - getTotalCanje();
        return remaining > 0 ? remaining : 0
    }

    const formRef = useRef();
    const model = Schema.Model({
        tipo_retiro: Schema.Types.StringType(),
        nombre: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
        apellido: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
        email: Schema.Types.StringType()
            .isRequired("Este campo es obligatorio.")
            .isEmail("Por favor, introduce una dirección de correo electrónico válida."),
        celular: Schema.Types.NumberType()
            .isRequired("Este campo es obligatorio.")
            .min(100000000, "Celular debe tener 9 dígitos")
            .max(999999999, "Celular debe tener 9 dígitos"),
        region: Schema.Types.NumberType().when ( schema => {
            return tipoEntrega === 'despacho' ? Schema.Types.NumberType()
            .isRequired("Este campo es obligatorio.")
            .min(1, "Debe seleccionar una región") : Schema.Types.StringType();
        }),
        comuna: Schema.Types.NumberType().when (schema => {
            return tipoEntrega === 'despacho' ?  
            Schema.Types.NumberType()
            .isRequired("Este campo es obligatorio.")
            .min(1, "Debe seleccionar una comuna") : Schema.Types.StringType();
        }),
        direccion: Schema.Types.StringType().when ( schema => {
            return tipoEntrega === 'despacho' ? Schema.Types.StringType().isRequired('El campo es obligatorio.') : Schema.Types.StringType();
        }),
        direccion_nro: Schema.Types.StringType().when (schema => {
            return tipoEntrega === 'despacho' ? Schema.Types.StringType().isRequired('El campo es obligatorio.') : Schema.Types.StringType();
        }),
        direccion_dpto: Schema.Types.StringType().when ( schema => {
            console.log(tipoEntrega)
            return tipoEntrega === 'despacho' ? Schema.Types.StringType().isRequired('El campo es obligatorio.') : Schema.Types.StringType();
        }),
        info: Schema.Types.StringType()
    });
  
    const [registerStatus, setRegisterStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [urlWebpay, setUrlWebpay] = useState('');
    const [tokenWs, setTokenWs] = useState('');
  
    const handleSubmit = async () => {
        setLoading(true);
        if (!formRef.current.check()) {
            console.log(model.data)
            toaster.push(
                <Message type="error">
                Existen campos que no cumplen con las validaciones
                </Message>
            , {placement: 'topCenter'});

            setLoading(false);
            return;
        }

        try {
            const data = await Api.CanjeCheckout({...model.data, tipo_retiro: tipoEntrega, cart});

            if (data.status) {
                
                if (tipoEntrega === 'retiro') {
                    history(`/canjes/voucher?buy_order=${data.buy_order}`);
                }

                if (tipoEntrega === 'despacho' && data.token_ws) {
                    setTokenWs(data.token_ws);
                    setUrlWebpay(data.url);
                    console.log(data.url, data.token_ws);
                    
                    setTimeout(function() {
                        setRegisterStatus(true);
                        setLoading(false);
                        document.getElementById("form_webpay").submit();
                    }, 200)
                }
            } else {
                console.log("aca",  Object.keys(data.validations), "Aca")
                if (data.validations !== undefined) {
                    let validations = Object.keys(data.validations);
                    let errores = Object.keys(data.validations.errors);

                    console.log("errores", errores, "validations: ", data.validations.errors);

                    toaster.push(
                        <Message type="error">
                        {
                            errores.map ( (item, index) => (
                                <span key={index}>{data.validations.errors[item]}<br /></span> 
                            ))
                        }
                        </Message>
                    );
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const [costoDespacho, setCostoDespacho] = useState(0);

    const [courier, setCourier] = useState('Seleccione comuna');

    const onChangeComuna = (e) => {
        
        const comunaId = e;
        const urlCheckDespachalo = `${process.env.REACT_APP_CHECK_COMUNA_DESPACHALO_ENDPOINT}/${comunaId}`;

        axios.get(urlCheckDespachalo).then ( res => {

            const isDespachalo = res.data.comuna_despachalo;
            
            if (isDespachalo) {
                setCostoDespacho(3000);
                setCourier('despachalo');
                return
            }

            const comunaNombre = options_comuna.find( item => item.id === comunaId).nombre;

            const paramsPentacrom = `comunaId=${comunaId}&comuna=${comunaNombre}&alto=0.5&ancho=30&largo=30&peso=0.2&response_type=json`;
            const urlCheckPentacrom  = `${process.env.REACT_APP_GET_TARIFA_PENTACROM_ENDPOINT}?${paramsPentacrom}`;
            
            axios.get(urlCheckPentacrom).then ( res => {
                setCostoDespacho(res.data.precio);
                setCourier('pentacrom');
            })
        })

        setComuna(comunaId)
    }
	return (
		<LayoutCanjes>

            <form name="form_webpay" action={urlWebpay} method="POST" id="form_webpay">
                <input type="hidden" name="token_ws" value={tokenWs} onChange={setTokenWs} />
            </form>

            <Form layout="horizontal" className="mt-3" model={model} ref={formRef} >
                <Row style={{padding: '20px'}}>
                    <Col xs={12} md={6} lg={6}>
                        <h3>
                            { tipoEntrega === 'retiro' ? 'Retiro en tienda' : 'Despacho'}
                        </h3>
                        <br />

                        <RadioGroup name="tipo_retiro" inline 
                                    appearance="picker" 
                                    value={tipoEntrega} 
                                    defaultValue="retiro" 
                                    onChange={onRadio}>

                            <Radio value="retiro">Retiro en tienda</Radio>
                            <Radio value="despacho">Despacho</Radio>
                        </RadioGroup>

                        {
                            tipoEntrega === 'despacho' && (
                                <>
                                    <Panel title="Datos de entrega" bordered className="mb-3">
                                        <p className="text-muted">
                                            <strong>Datos de entrega</strong>
                                        </p>
                                        <br />
                                        <Row className="">
                                            <Col xs={12} sm={6} md={6} className="mb-3">
                                                <Form.ControlLabel>Región*</Form.ControlLabel>
                                                <Form.Control
                                                    style={{ minWidth: '100%'}}
                                                    defaultValue={region}
                                                    value={region}
                                                    name="region"
                                                    accepter={regionSelector}
                                                    placeholder="Seleccionar Región"
                                                    onChange={(e) => {
                                                        getComumunes(e);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} className="mb-3">
                                            <Form.ControlLabel>Comuna*</Form.ControlLabel>
                                            <Form.Control
                                                    style={{ width: '100%'}}
                                                    defaultValue={comuna}
                                                    value={comuna}
                                                    name="comuna"
                                                    accepter={comunaSelector}
                                                    onChange={ (e) => onChangeComuna(e) }
                                                    placeholder="Seleccionar Comuna"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col xs={12} md={6} lg={6}>
                                                <label>Dirección</label>
                                                <Form.Control name="direccion"/>
                                            </Col>
                                            <Col xs={12} md={6} lg={6}>
                                                <Row>
                                                    <Col xs={6} md={6} lg={6}>
                                                        <label>Número</label>
                                                        <Form.Control 
                                                            name="direccion_nro" 
                                                            style={{ maxWidth: '100%'}} />
                                                    </Col>

                                                    <Col xs={6} md={6} lg={6}>
                                                        <label> Dpto/Block/Casa </label>
                                                        <Form.Control 
                                                            name="direccion_dpto" 
                                                            style={{ maxWidth: '100%'}} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </>
                            )
                        }
                            <Row className="mb-3">
                                <Col xs={12} md={6} lg={6}>
                                    <label>Nombre</label>
                                    <Form.Control name="nombre"/>
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <label>Apellido</label>
                                    <Form.Control name="apellido"/>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={12} md={12} lg={12}>
                                    <label>Email</label>
                                    <Form.Control name="email"/>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={12} md={12} lg={12}>
                                    <label>Celular</label>
                                    <Form.Control name="celular"/>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={12} md={12} lg={12}>
                                    <label>Información Adicional</label>
                                    <Form.Control 
                                        rows={5} 
                                        style={{width: '100%'}} 
                                        name="info" accepter={Textarea} />
                                </Col>
                            </Row>
                    </Col>
                    <Col xs={12} lg={6} md={6}>
                        <h3>
                            <i className="bi bi-cart-fill"></i> Productos a canjear
                        </h3>
                        <br />
                        <List hover>
                        {cart.map((item, index) => (
                            <CartItem item={item} index={index} key={index}/>
                        ))}
                        </List>
                        <div className="py-3">
                            <Row>
                                <Col xs={5} lg={5} md={5}>
                                    <h6>Puntos disponibles </h6>
                                </Col>
                                <Col xs={7} lg={7} md={7}>
                                    <p style={dataStyle}>{ points } puntos</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5} lg={5} md={5}>
                                    <h6>Total canje</h6>
                                </Col>
                                <Col xs={7} lg={7} md={7}>
                                    <p style={dataStyle}>{ getTotalCanje() } puntos</p>
                                </Col>
                            </Row>
                            <small>*Puntos restantes: { getRemainingPoints() } puntos</small>
                            {
                                outOfCredit() && <>
                                    <div className="alert alert-danger mt-3" role="alert">
                                        No tienes puntos suficientes para realizar el canje, por favor elimina productos del carro.
                                    </div>
                                </>
                            }

                            <hr />
                            {
                                tipoEntrega === 'despacho' && <>
                                    <div>

                                        <table className="table bordered">
                                            <thead>
                                                <tr>
                                                    <th>Courier</th>
                                                    <th>Días Hábiles Aprox.</th>
                                                    <th>Valor</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        { courier == 'pentacrom' && <img src={pentacromImg} width={50}/> }
                                                        { courier == 'despachalo' && <img src={despachaloImg} width={50} /> }
                                                        { courier }
                                                    </td>
                                                    <td>10</td>
                                                    <td>${ formatNumeric(costoDespacho) }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <small>Serás redireccionado a webpay para pagar el despacho.</small>
                                    </div>
                                </>
                            }
                        </div>
                        <Button 
                            color="green" 
                            appearance="ghost" 
                            style={{ width: '100%'}} 
                            onClick={handleSubmit}
                            disabled={outOfCredit() || loading}
                        >
                            Canjear 

                            {
                                loading && <>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                </>
                            }
                        </Button>

                        <Modal size={'xs'} open={open} onClose={handleClose}>
                            <Modal.Header>
                                <Modal.Title>Canje exitoso</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Message showIcon type="success" header="Canje exitoso">
                                    Su canje ha sido procesado exitosamente.
                                    <hr />
                                    En los proximos minutos llegara una copnfirmacion mediante correo electronico.
                                </Message>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={handleClose} appearance="primary">
                                    Aceptar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </Form>

            
		</LayoutCanjes>
	)
}

export default Checkout;