export function encode(value) {
    if (value !== null) {
        return btoa(btoa(btoa(value)));
    }
    return value
}

export function decode(value) {
    if (value !== null) {
        return atob(atob(atob(value)));
    }
    return value
}

export function formatRut(rut) {
    if (rut) {
        return rut.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
    }
    return '';
}

export function unformatRut(rut) {
    return rut ? rut.replace(/\./g, '').replace('-', '') : '';
}
export function validateRut(rutValue) {
    const rut = unformatRut(rutValue);
    const rexp = new RegExp(/^([0-9])+-([kK0-9])+$/);
    if (rut.match(rexp)) {
        const RUT = rut.split('-');
        const elRut = RUT[0].split('');
        let factor = 2;
        let suma = 0;
        let dv;
        for (let i = (elRut.length - 1); i >= 0; i--) {
            factor = factor > 7 ? 2 : factor;
            suma += parseInt(elRut[i]) * parseInt(factor++);
        }
        dv = 11 - (suma % 11);
        if (dv === 11) {
            dv = 0;
        } else if (dv === 10) {
            dv = 'k';
        }

        if (dv.toString() === RUT[1].toLowerCase()) {
            return true;
        }
        return false;
    }
    return false;
}

export function formatPhone(phone) {
    if (phone) {
        return phone.replace(/[.-]/g, '').replace(/^(\d{3})(\d{4})(\d{4})$/, '($1) $2 $3');
    }
    return '';
}

export function unformatPhone(phone) {
    return phone ? phone.replace(/[^0-9.]/g, '') : '';
}
export function formatDateFull(date) {
    if (date) {
        const month = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        let fecha = date.split("-");
        return `${fecha[2]} de ${month[(parseInt(fecha[1]) - 1)]} de ${fecha[0]}`;
    }
    return '';
}

export function formatDate(date) {
    if (date) {
        const month = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        let fecha = date.split("-");
        return `${month[(parseInt(fecha[1]) - 1)]} de ${fecha[0]}`;
    }
    return '';
}
export function getEdadCuota(date) {
    var hoy = new Date();
    var cumpleanos = new Date(date);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }

    return edad;
}
export function getMonto(edad, region) {
    const response = {};
    let incorporacion = 6000;
    let cuota = 2000;
    let minnumerocuota = 3;
    let numcuota = 3;

    if (region && region.id === 13 || region === 13) {
        cuota = 3000;
        minnumerocuota = 2;
        numcuota = 2;
    }

    if (edad <= 12) {
        incorporacion = 6000;
        cuota = 500;
        minnumerocuota = 12;
        numcuota = 12;
    }

    if (edad >= 13 && edad <= 17 || edad >= 60) {
        incorporacion = 6000;
        cuota = 1000;
        minnumerocuota = 6;
        numcuota = 6;
    }

    response.incorporacion = incorporacion
    response.cuota = cuota
    response.minnumerocuota = minnumerocuota
    response.numcuota = numcuota

    return response;
}
export function formatNumeric(number) {
    return new Intl.NumberFormat("de-DE").format(parseInt(number))
}