import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Api from "../../api";
import { cleanCart } from '../../store/canjes'

const useCanjeDetails = (buyOrder) => {

    const [canje, setCanje] = useState();
    const [loading, setLoading]  = useState(false);
    const dispatch = useDispatch();

    const getCanje = async () => {
        setLoading(true);
        dispatch(cleanCart())
        const resp = await Api.GetCanjeData(buyOrder);
        setCanje(resp.data);
        setLoading(false);
    }

    useEffect( () => {
        getCanje();
    }, []);

    return {
        canje,
        loadingCanje: loading
    }
}

export default useCanjeDetails;