import React from "react";

const Previo = ({ local, visita }) => {
  return (
    <>
      <div className="col-md-12 mt-3 pb-3 border-bottom">
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-12">
            <h5 className="title">
              <strong>ÚLTIMO RESULTADO</strong>
            </h5>
          </div>
          <div className="row">
            <div className="col-5">
              <img
                src={`https://mi.csdcolocolo.cl/imagenes/equipos/${local?.thumbnail}`}
                className="w-100 img-fluid"
                alt={`${local?.name}`}
              />
            </div>
            <div className="col-1">{local?.goals}</div>
            <div className="col-1">{visita?.goals}</div>
            <div className="col-5">
              <img
                src={`https://mi.csdcolocolo.cl/imagenes/equipos/${visita?.thumbnail}`}
                className="w-100 img-fluid"
                alt={`${visita?.name}`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Previo;
