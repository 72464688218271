import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LayoutAccount from "../../Components/Layout/LayoutAccount";
import * as Api from "../../api";

const ListPaymentHistory = ({type = 'normal', title = ''}) => {

	const [history, setHistory] = useState([]);

	const getPaymentHistory = async () => {
		const res = await Api.getPaymentHistory();

		if (type === 'normal') {
			setHistory(res.data.history);
		} else {
			setHistory(res.data.history_extra);
		}
	}

	useEffect( () => {
		getPaymentHistory();
	}, []);
	return (
		<div className="container p-1 pt-3">
			<h4>{title}</h4>
			<table className="table">
			  <thead>
			    <tr>
			      <th>Nº</th>
			      <th>Fecha</th>
			      <th>Estado</th>
			      <th>Acciones</th>
			      <th>Receptor</th>
			      <th>Monto</th>
			      <th>Periodo</th>
			      <th>Comprobante</th>
			    </tr>
			  </thead>
			  <tbody>
			    {
			    	history.map ( item => (
			    		<tr key={item.id}>
							<th>{item.pago_relacionado?.id}</th>
							<th>{item.fecha}</th>
							<th>{item.pago_relacionado?.estado === 2 ? 'PAGADO' : 'PENDIENTE'}</th>
							<th>{item.tipo}</th>
							<th>{item.medio}</th>
							<th>{item.rut}</th>
							<th>{item.valor}</th>
							<th>{item.periodo}</th>
							<th>
								<a href={item.url_voucher}>Ver comprobante</a>
							</th>
					    </tr>
			    	))
			    }
			  </tbody>
			</table>
		</div>
	)
};	

export default ListPaymentHistory;