import React from "react";
import LayoutCanjes from "../../Components/Layout/LayoutCanjes";
import { useState, useEffect } from 'react';
import Pagination from "../../Components/Pagination/Pagination";
import {
  Panel,
  Divider,
  Badge,
  Button,
  Modal,
  Grid,
  TagPicker,
  Input,
  InputGroup,
  Message,
  InputNumber,
  Form
} from 'rsuite';
import * as Api from "../../api";
import { Row, Col } from 'react-bootstrap';
import SearchIcon from '@rsuite/icons/Search';
import { useSelector, useDispatch } from 'react-redux'
import Loader from './Loader';
import usePoints from "../../hooks/account/usePoints";
import { addToCart, removeFromCart, addQuantity, removeQuantity } from '../../store/canjes'
import { toaster } from 'rsuite';
import './Canjes.scss';

const Canjes = () => {

    const cart = useSelector((state) => state.cartReducer.products);

    const dispatch = useDispatch();
    const { points } = usePoints();
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [loadingProductsInfo, setLoadingProductsInfo] = useState(false);
    const [pageData, setPageData] = useState({
        total: 1,
        limit: 12,
        activePage: 1
    });
    const [loadingCart, setLoadingCart] = useState(false);

    const setActivePage = (page) => {
        getProducts({ page: page });
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onPickTag = (values, event) => {
        if (values == null) {
        values = [];
        }
        setCategories(values);
        getProducts({
            page: 1,
            search: search,
            categories: values.length > 0 ? values.toString() : ''
        });
    }

    const [search, setSearch] = useState('');

    const onType = (value) => {
        setSearch(value);
    }

    const onSearch = () => {
        getProducts({
            page: 1,
            search: search,
            categories: categories.length > 0 ? categories.toString() : ''
        });
    }

    const getCategories = async () => {
        const res = await Api.getCategoryCanjes();
        setTags(res.categories);
    }
    const getProducts = async (data) => {
        setLoadingProductsInfo(true);
        const res = await Api.getProductsCanjes(data);
        let newData = res.products.data;
        setProducts(newData);
        setPageData({
            total: res.products.total,
            limit: 12,
            activePage: res.products.current_page
        });
        setLoadingProductsInfo(false);
    }

    useEffect(() => {
        getProducts({ page: 1 });
        getCategories();
    }, []);

    const add = (item) => {
        setLoadingCart(true);
        dispatch(addToCart(item))
        setLoadingCart(false);

        console.log(item.quantity);
        toaster.push(<Message type="success">Canje agregado a la lista.</Message>, {
            placement:'topEnd'
        })
    }

    const isInCart = (id) => {
        console.log(cart)
        return Boolean(cart.find( item => item.id === id))
    }

    const isNotInCart = (id) => {
        return !isInCart(id);
    }

    const removeItem = (item) => {
        dispatch(removeQuantity(item));
    }

    const getCurrentQuantity = (item) => {
        const product = cart.find( p => p.id === item.id);

        if (!product) return;

        return product.quantity;
    }

  return (
    <LayoutCanjes>
        <div className="alert alert-primary text-center" role="alert">
            Tienes <strong>{ points }</strong> puntos acumulados para canjear.
        </div>
      <h3 className="py-3">Productos canjeables</h3>

      {loadingProductsInfo ?
        <>
          <div className="col-12 d-flex justify-content-center" style={{ minHeight: '370px' }}>
            <div className="spinner-border m-5" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </> :
        <Grid fluid style={{ minHeight: '370px' }}>
          <Row>
            <Col md={12} lg={6} xs={12} className="mb-3">
              <label>Busqueda por nombre:</label>
              {/* <div className="input-group">
                <input className="form-control" placeholder="Nombre producto" value={search} onChange={onType} />
                <button className="btn btn-danger" onClick={onSearch}>Buscar</button>
              </div> */}
              <InputGroup inside>
                <Input placeholder="Nombre producto" value={search} onChange={onType} />
                <InputGroup.Button onClick={onSearch}>
                  <SearchIcon /> Buscar
                </InputGroup.Button>
              </InputGroup>
            </Col>
            <Col md={12} lg={6} xs={12}>
              <label>Filtrar por tags:</label>
              <TagPicker data={tags} defaultValue={categories} style={{ width: '100%' }} onChange={onPickTag} />
            </Col>
          </Row>
          {products.length <= 0 ?
          <h3>No se encontraron Resultados</h3>
          :
          <Row>
            {
              products.map(item => (
                <Col xs={12} md={3} lg={3} style={{ minHeight: '500px' }} key={item.id}>
                  <Panel shaded bordered bodyFill style={{
                    display: 'inline-block',
                    marginTop: '10px',
                    minHeight: '470px'
                  }}>
                    <img src={`https://picsum.photos/seed/picsum/200/300`} width={'100%'} height={200} />
                    <Panel>

                      <div className="product-title" style={{ height: '50px' }}>
                        {item.title}
                      </div>

                      <Modal open={open} onClose={handleClose} size="md" overflow={false}>
                        <Modal.Header>
                          <Modal.Title>{item.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row>
                            <Col md={6} lg={6} xs={12}>
                              <img src={`${process.env.REACT_APP_URL_S3}canjes/${item.imagen}`} width={'300'} />
                            </Col>


                            <Col md={6} lg={6} xs={12}>
                              <p style={{ fontSize: '16px' }}>{item.title}</p>
                              <p className="text-bold">
                                {item.description}
                              </p>
                              <div>
                                <Badge content={item.points} maxCount={10000} /> Puntos
                              </div>
                              <div style={{ height: '25px', fontSize: '12px' }}>
                                {item.categories.map((category, i, row) => {
                                  return (i + 1 === row.length) ?
                                    <span> {category.nombre} </span>
                                    :
                                    <span> {category.nombre}, </span>
                                })}
                              </div>
                              <br />
                              <p>
                                <Button color="blue" appearance="ghost">
                                  Agregar a la lista
                                </Button>
                              </p>
                            </Col>
                          </Row>
                        </Modal.Body>
                      </Modal>
                      <p style={{ height: '70px' }}>
                        {item.description.length <= 100 ? item.description : item.description.substr(0, 90) + '...'}
                      </p>
                      <div style={{ height: '25px' }}>
                        <Badge content={item.points} maxCount={10000} /> Puntos
                      </div>
                        <div className="d-flex justify-content-center align-items-center border p-2">
                            <button onClick={ () => add(item)}>+</button>
                            <span className="text-center" style={{minWidth: '50px'}}>{ getCurrentQuantity(item) }</span>
                            <button onClick={ () => removeItem(item)}>-</button>
                        </div>
                      <br />
                      <p>

                        { 
                            isNotInCart(item.id) && <>
                                <Button color="blue" appearance="ghost" onClick={ (e) => add(item) }>
                                    Agregar a la lista { isInCart() === true ? 'true' :  'false'}
                                    { loadingCart && <Loader /> }
                                </Button>
                            </>
                        }
                      </p>
                        { 
                            isInCart(item.id) && 
                            <Button color="red" appearance="ghost" onClick={ (e) => dispatch(removeFromCart(item.id)) }>
                                Remover de la lista
                            </Button>
                        }
                        
                    </Panel>
                  </Panel>
                </Col>
              ))
            }
          </Row>}
          <Divider />

          <Row className="d-flex justify-content-center" style={{textAlign: 'center'}}>
            <Col md={12}>
              <Pagination
                className="pagination-bar"
                currentPage={pageData.activePage}
                totalCount={pageData.total}
                pageSize={pageData.limit}
                onPageChange={page => setActivePage(page)}
              />
            </Col>
          </Row>

        </Grid>}
    </LayoutCanjes>
  );
};

export default Canjes;