import { useEffect, useState } from "react";
import LayoutAccount from "../../Components/Layout/LayoutAccount";
import ListPaymentHistory from "../../Components/Account/ListPaymentHistory";
import * as Api from "../../api";

const PaymentHistory = () => {

	return (
		<LayoutAccount>
			<ListPaymentHistory type="normal" title="Historial de Pagos" />
			<ListPaymentHistory type="extraordinaria" title="Historial de Pagos Cuotas Extraordinarias" />
		</LayoutAccount>
	)
};	

export default PaymentHistory;