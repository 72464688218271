import { encode, decode } from "./Utils";
class Local {
    setInformations = (informations) => {
        localStorage.setItem('informations', encode(JSON.stringify(informations)))
    }
    getInformations = () => {
        return {
            data: JSON.parse(decode(localStorage.getItem('informations')))
        }
    }
    setToken = (token) => {
        localStorage.setItem('t', encode(token))
    }
    getToken = () => {
        return {
            token: decode(localStorage.getItem('t'))
        }
    }
    setStatus = (status) => {
        localStorage.setItem('s', encode(status))
    }
    getStatus = () => {
        return {
            status: decode(localStorage.getItem('s'))
        }
    }
    close = () => {
        localStorage.clear()
    }
}
export default new Local()