import React from "react";

const SocioError = ({ status }) => {
  const type = {
    1: {
      title: "Renuncia",
      description: "Según nuestros registros este RUT se encuentra renunciado.",
    },
    2: {
      title: "Eliminado",
      description: "Según nuestros registros este RUT se encuentra eliminado.",
    },
    3: {
      title: "No Habilitado", //difunto
      description:
        "Según nuestros registros este RUT se encuentra no habilitado.",
    },
  };
  return (
    <div className="mt-3">
      <h2>{type[status].title}</h2>
      <p>{type[status].description}</p>
    </div>
  );
};

export default SocioError;
