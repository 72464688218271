import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import { formatDate, formatNumeric } from "../../Service/Utils";
import * as Api from "../../api";
import LocalStorage from "../../Service/LocalStorage";

const CardCuota = ({type = 'normal', title, description = '', route = '/pago/cuota', btnText='PAGAR'}) => {

	const [info, setInfo] = useState([]);
	const [total, setTotal] = useState(0);
	const [n_cuotas, setNumero] = useState(0);
	const [active, setActive] = useState(false);

	const getTotal = async (e) => {
		const response = await Api.getTotalSocio();
		setTotal(response.total);
		setInfo(LocalStorage.getInformations().data);
	};

	const getNumeroCuotas = async (e) => {
		const response = await Api.getCuotasPendientes({
			rut: info?.rut,
		});

		setNumero(response.cuotas_pendientes);
		setActive(true);
	};

	useEffect(() => {
		getNumeroCuotas();
	}, []);

	const CuotasPendientes = ({n_cuotas}) => {
		return (
			<>
				{parseInt(n_cuotas) === 0 ?  (
					<span className="green">Se encuentra al día</span>
				) : (
					<span className="red">
					{formatNumeric(n_cuotas)} cuotas pendientes
					</span>
				)}
			</>
		)
	}

	return (
		<>
			<div className="col-12 col-md-4">
				<Card className="mb-4">
					<Card.Body>
						<Card.Title>
							{ title }
						</Card.Title>
						<Card.Subtitle className="mb-2 text-muted">
							{ type === 'normal' && <CuotasPendientes n_cuotas={n_cuotas} /> }
						</Card.Subtitle>
						<Card.Text>
							{ description }
						</Card.Text>
						<Card.Footer className="text-center">
						{active ? (
							<Link
								to={route}
								state={{ number: n_cuotas, type_pay: "cuotas" }}
								className="btn bg-red"
							>
								{btnText}
							</Link>
						) : (
							<Spinner animation="grow" className="mt-3" />
						)}
						</Card.Footer>
					</Card.Body>
				</Card>
			</div>
		</>
	)
}

export default CardCuota;