import React, { useState } from "react";
import {Input, InputGroup, List, Message} from "rsuite";
import * as Api from "../../api";
import { Card, Spinner } from "react-bootstrap";

const InvitedAccounts = ({}) => {

    const [form, setForm] = useState({
        email: ''
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const [genericError, setGenericError] = useState();
    const [invitationSent, setInvitationSent] = useState(false);

    const handleInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleInvite = async () => {
        setLoading(true);
        setInvitationSent(false);

        const data = await Api.sendInviteNewPartner(form);

        if (data.validations) {
            setErrors(data.validations.message);
            setGenericError('');
            setLoading(false);
            return;
        }

        if (!data.status) {
            setGenericError(data.data);
            setErrors('');
            setLoading(false);
            return;
        }

        if (data.status) {
            setInvitationSent(true);
            setErrors('')
            setGenericError('');
            setForm({ rut: ''})
        }

        setLoading(false);
    }

	return (
		<>
			<div className="row m-2">
				<div className="col-12 col-md-12">
					<h4><strong><i className="fas fa-user"></i>Invitar socios</strong></h4>

					<p>
                        Ahora puedes nuevos socios y acumular puntos para canjear productos.
                        <br /><br />
                        Para esto, indica a continuación el email de la persona que deseas invitar:
                    </p>
                    <p>
                    <small className="text-muted">(La persona debe utilizar el enlace de tu invitacion para que los puntos sean acumulados a tu cuenta).</small>
                    </p>
                    <div className="d-flex">
                        <label htmlFor="email" className="visually-hidden">Email</label>
                        <input type="text" name="email" className="form-control" id="rut" placeholder="test@example.org" onChange={handleInput} value={form.email}/>
                        <button className="d-flex justify-content-between align-items-center btn btn-primary" onClick={handleInvite} disabled={loading}>
                            Enviar invitación 
                            { loading ? <Spinner animation="grow" size="sm" /> : <></>}
                        </button>
                    </div>
                    {
                        genericError ? 
                        <>
                            <span className="text-danger">{ genericError }</span>
                            <br />
                        </> : <></>
                    }
                    { errors && <span className="text-danger">{ errors }</span> }
                    {
                        invitationSent ? 
                        <>
                            <Message showIcon type="success" header="Invitación enviada" closable>
                                Se ha enviado un correo con la invitación.
                            </Message>
                        </> : <></>
                    }
				</div>
			</div>
		</>
	)
}

export default InvitedAccounts;