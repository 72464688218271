import * as Api from "../../api";
import { Avatar } from "rsuite";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LocalStorage from "./../../Service/LocalStorage";
import { Navbar } from "react-bootstrap";

const UserInfoBanner = ({info}) => {

    const [fullName, setFullName] = useState('');
    const history = useNavigate();
    const getName = async () => {
        const res = await Api.getProfileData();
        setFullName(res.data.nombre_completo);
    }
    
    const handleClose = () => {
        history("/");
        LocalStorage.close();
    };

    useEffect( () => {
        getName();
    }, [])

    return (
        <>
            <Navbar.Collapse className="justify-content-end">
                {info?.imagen !== null && (
                    <Avatar
                        size="lg"
                        circle
                        src={`${process.env.REACT_APP_URL_S3}${info?.imagen}`}
                        className="me-2"
                    />
                )}
                <Navbar.Text>
                    <p className="m-0">
                        Bienvenido <a>{fullName}</a>
                    </p>
                    <p className="m-0 close-sesion" onClick={() => handleClose()}>
                        Cerrar Sesión
                    </p>
                </Navbar.Text>
          </Navbar.Collapse>
        </>
    )
}

export default UserInfoBanner;