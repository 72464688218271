import React, { useRef, useState } from "react";
import * as Api from "../../api";
import { Spinner } from "react-bootstrap";

const PasswordUpdater = (props) => {

	const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
    	current_password: '', 
    	new_password: ''
    });
    const [hasError, setHasError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const updatePassword = async () => {
    	setLoading(true);

    	const res = await Api.updatePassword({
    		current_password: password,
    		new_password: newPassword
    	});

    	let validations = res.validations !== undefined ? res.validations : []

    	if (validations) {
    		setSuccessMessage('');
    		setErrors(validations.errors);
    		setHasError(true);
    	}

    	if (res.status) {
    		setSuccessMessage(res.data);
    		setErrors({current_password: '', new_password: ''});
    		setHasError(false);
    		setPassword('');
    		setNewPassword('');
    	}

    	setLoading(false);
    }



	return (
		<>
			<div className="row">
				<h2 className="title">
			      <strong>Cambiar contraseña</strong>
			    </h2>
			    <div className="col-6">
			        <label>Contraseña actual</label>
			        <p>
			        <input
			        type="password"
			        value={password}
			        onChange={ ({target}) => setPassword(target.value)}
			        className="form-control"
			        />
			        </p>
			    </div>

			    <div className="col-6">
			        <label>Nueva contraseña</label>
			        <p>
			        <input
			        type="password"
			        value={newPassword}
			        onChange={ ({target}) => setNewPassword(target.value)}
			        className="form-control"
			        />
			        </p>
			    </div>
			</div>
			{
				hasError && errors && (
					<p className="d-flex justify-content-start align-items-start flex-column">
						<span className="text-danger">{errors.current_password}</span> <br /><br />
			            <span className="text-danger">{errors.new_password}</span>
					</p>
				)	
			}
			{
				successMessage && (
					<div className="alert alert-success">
			    		{ successMessage }
			    	</div>
				)
			}
			<div className="row">
			    <div className="col-6">
			    	<button className="btn btn-primary" disabled={loading} onClick={updatePassword}>
			    		Cambiar contraseña { loading && <Spinner animation="grow" size="sm" /> }
			    	</button>
			    </div>
			</div>
		</>
	)
}

export default PasswordUpdater;