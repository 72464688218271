import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Logo from "../../Components/Logo/Logo";
import * as Api from "../../api";
import { unformatRut } from "../../Service/Utils";
import { Col, Row, Spinner } from "react-bootstrap";
import LocalStorage from "./../../Service/LocalStorage";
import { Form, Schema, Message, toaster } from "rsuite";

const Login = () => {
  const history = useNavigate();
  const formRef = useRef();
  const [active, setActive] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const model = Schema.Model({
    rut: Schema.Types.StringType()
      .isRequired("Este campo es obligatorio.")
      .pattern(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "Rut no válido"),
    password: Schema.Types.StringType().isRequired(
      "Este campo es obligatorio."
    ),
  });
  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Message type="error">
          Existen campos que no cumplen con las validaciones
        </Message>
      );
      return;
    }
    setActive(false);
    setDisabled(true);
    const params = {
      rut: unformatRut(model.data.rut),
      password: model.data.password,
    };
    const data = await Api.SingIn(params);
    console.info(data);
    if (data.validations !== undefined) {
      toaster.push(
        <Message type="error">{data.validations.errors["rut"][0]}</Message>
      );
      setDisabled(false);
      setActive(true);
      return;
    }
    if (data.response) {
      LocalStorage.setToken(data.token);
      const response = await Api.getInfoSocio();
      await setTiendaCookie(response.data);
      LocalStorage.setInformations(response.data);
      LocalStorage.setStatus(0);
      const params = new URLSearchParams(window.location.search).get(
        "continue"
      );

      console.info(response.data.activo);

      if (response.data.activo === 0) {
        history("/validation/first");
      } else {
        history("/dashboard");
      }
    } else {
      toaster.push(<Message type="error">{data.msg}</Message>);
      setDisabled(false);
      setActive(true);
    }
  };

  const setTiendaCookie = async (data) => {
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;
    document.cookie = `login=${data.legacy_auth_token}; path=/; domain=${domain}`;
    console.log(document.cookie)
  }

  useEffect( () => {
      const statusValidation = new URLSearchParams(window.location.search).get('status');
      if (statusValidation === 'true') {
          setValidated(true);
      }
  });

  useEffect(() => {
    LocalStorage.close();
  }, []);
  const TextField = ({ name, label, type, accepter, ...rest }) => (
    <Form.Group controlId={name} className="mb-3">
      <Form.ControlLabel>{label}</Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} type={type} />
    </Form.Group>
  );

  return (
    <>
      <div className="container-fluid log h-100 black">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-md-6 text-end white h-100">
            <div className="login py-3 h-100">
              <Logo />
              { validated && (
                  <Message showIcon type="success" header="Email validado" closable>
                      Email validado exitosamente.
                  </Message>
              )}
              <h2>Inicia sesión</h2>
              <p>
                Si ya eres socio/a de Colo-Colo, ingresa aquí. Si no recuerdas tu contraseña, escríbenos a socios@csdcolocolo.cl indicando tu RUT para ayudarte.
              </p>
              <div className="form">
                <Form model={model} ref={formRef}>
                  <Row>
                    <Col xs={12} sm={12} md={12}>
                      <TextField
                        name="rut"
                        type="text"
                        label="Rut * (sin puntos y sin guión)"
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <TextField
                        name="password"
                        type="password"
                        label="Password"
                      />
                    </Col>
                  </Row>
                  <div className="text-center">
                    {active ? (
                      <button
                        className="btn bg-red"
                        disabled={disabled}
                        onClick={handleSubmit}
                      >
                        Ingresar
                      </button>
                    ) : (
                      <Spinner animation="grow" className="mt-3" />
                    )}
                  </div>
				  <Link to="/password/recover-password" className="">
                    Recuperar contraseña
                  </Link>
                </Form>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="texto">
              <h2>¡Hazte socio/a de Colo-Colo!</h2>
              <p>
                Únete a la gran familia Colocolina y siente desde ya el orgullo de ser socio/a del club más lindo del mundo
              </p>
              <div className="recovery mt-4">
                <div>
                  <Link to="/register" className="link tx-white">
                    ¡Quiero ser parte de Colo-Colo!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default Login;
