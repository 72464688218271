import { Card } from "react-bootstrap";

const ProfileCard = ({profilePictureUrl}) => {
	return (
		<>
			<div className="mt-3">
				<Card className="mb-4">
					<Card.Body>
						<Card.Title>Tu imagen de Credencial:</Card.Title>
						<Card.Text>
							<img
						      src={profilePictureUrl}
						      alt=""
						      className="m-2 img-default"
						    />
						</Card.Text>

					</Card.Body>
				</Card>
			</div>
		</>
	);
};

export default ProfileCard;
