import React from "react";
import { Container } from "react-bootstrap";
import LayoutFull from "../../Components/Layout/LayoutFull";
import { Link } from "react-router-dom";

const CardSubscriptionError = () => {

	return (
		<>
		    <LayoutFull>
	      		<Container className="my-4">
					<div className="alert alert-danger">
						Error al inscribir la tarjeta.
					</div>

			        <Link to={"/dashboard"} className="btn">
	                  Volver
	                </Link>
				</Container>
			</LayoutFull>
		</>
	);
}

export default CardSubscriptionError;