import React, { useEffect, useState } from "react";
import * as Api from "../../api";
import LocalStorage from "../../Service/LocalStorage";
import Previo from "../Partido/Previo";
import Proximo from "../Partido/Proximo";

const Sidebar = () => {
  const [info, setInfo] = useState([]);

  const getInfoPartido = async (e) => {
    const response = await Api.getInfoPartidos();
    setInfo(response);
  };
  useEffect(() => {
    getInfoPartido();
  }, []);
  return (
    <>
      {info.previo && (
        <>
          <Previo {...info.previo} />
          <Proximo {...info.proximo} />
        </>
      )}
    </>
  );
};

export default Sidebar;
