import React, { forwardRef, useState } from "react";
import { Col, Form, Row, SelectPicker } from "rsuite";
import * as Api from "../../api";

const Selecters = (props) => {
  const [options_comuna, setoptions_comuna] = useState([]);
  const [region, setRegion] = useState(0);
  const [comuna, setComuna] = useState(0);
  const [genero, setGenero] = useState('');

  const options_region = [
    { value: 1, name: "I Región de Tarapacá" },
    { value: 2, name: "II Región de Antofagasta" },
    { value: 3, name: "III Región de Atacama" },
    { value: 4, name: "IV Región de Coquimbo" },
    { value: 5, name: "V Región de Valparaíso" },
    {
      value: 6,
      name: "VI Región del Libertador General Bernardo O'Higgins",
    },
    { value: 7, name: "VII Región del Maule" },
    { value: 8, name: "VIII Región del Biobío" },
    { value: 9, name: "IX Región de la Araucanía" },
    { value: 10, name: "X Región de Los Lagos" },
    { value: 11, name: "XI Región de Aysén" },
    { value: 12, name: "XII Región de Magallanes y Antártica" },
    { value: 13, name: "Región Metropolitana" },
    { value: 14, name: "XIV Región de Los Ríos" },
    { value: 15, name: "XV Región de Arica y Parinacota" },
    { value: 16, name: "XVI Región de Ñuble" },
  ];

  const options_genero = [
    { value: "m", name: "Masculino" },
    { value: "f", name: "Femenino" },
  ];

  const regionSelector = forwardRef((props, ref) => (
    <SelectPicker
      cleanable={false}
      data={options_region}
      valueKey="value"
      labelKey="name"
      {...props}
      ref={ref}
    />
  ));
  const comunaSelector = forwardRef((props, ref) => (
    <SelectPicker
      defaultValue={comuna}
      cleanable={true}
      data={options_comuna}
      valueKey="id"
      labelKey="nombre"
      {...props}
      ref={ref}
    />
  ));
  const generoSelector = forwardRef((props, ref) => (
    <SelectPicker
      cleanable={false}
      data={options_genero}
      valueKey="value"
      labelKey="name"      
      {...props}
      ref={ref}
    />
  ));
  const getComumunes = async (id) => {
    setComuna(0);
    const response = await Api.getCommunes(id);
    setoptions_comuna(response);
    setRegion(id);
  };
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <Form.ControlLabel>Género*</Form.ControlLabel>
          <Form.Group controlId="genero">
            <Form.Control
              name="genero"
              accepter={generoSelector}
              placeholder="Seleccionar Género"
              defaultValue={genero}
              onChange={ (e) => setGenero(e) }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12} md={12} className="mb-3">
          <Form.ControlLabel>Región*</Form.ControlLabel>
          <Form.Group controlId="region">
            <Form.Control
              defaultValue={region}
              name="region"
              accepter={regionSelector}
              placeholder="Seleccionar Región"
              onChange={(e) => {
                getComumunes(e);
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={24} sm={12} md={12} className="mb-3">
          <Form.ControlLabel>Comuna*</Form.ControlLabel>
          <Form.Group controlId="comuna">
            <Form.Control
              defaultValue={comuna}
              defaultChecked={comuna}
              name="comuna"
              accepter={comunaSelector}
              placeholder="Seleccionar Comuna"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default Selecters;
