import { useEffect, useState } from "react";
import * as Api from "../../api";

const useInfoCuotas = () => {

	const [info, setInfo] = useState({});
	
	const getCuotasPendientes = async () => {

		const res = await Api.getCuotasPendientes();
		
		setInfo({
			incorporacion: res.incorporacion,
		    valor_cuota:  res.valor_cuota,
		    min_cuotas:  res.min_cuotas,
		    numero_cuotas:  res.numero_cuotas,
		});
	}

	useEffect( () => {
		getCuotasPendientes()
	}, []);

	return info;
}

export default useInfoCuotas;