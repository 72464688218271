import { createSlice, current } from '@reduxjs/toolkit'

export const cartReducer = createSlice({
    name: 'cart',
    initialState: {
        products: [],
    },
    reducers: {
        addToCart: (state, action) => {
            const item = state.products.find( item => item.id === action.payload.id);
            if (item) {
                item.quantity++;
            } else {
                state.products.push({
                    ...action.payload, 
                    quantity: 1
                })
            }
        },
        removeFromCart: (state, action) => {
            // Items without the item to delete
            const filteredItems = state.products.filter( item => item.id !== action.payload);
            state.products = filteredItems;
        },
        addQuantity: (state, action) => {
            const item = state.products.find(item => item.id === action.payload.id);
            item.quantity++;
        },
        setQuantity: (state, action) => {
            const item = state.products.find(item => item.id === action.payload.item.id);
            
            if (item) {
                item.quantity = action.payload.quantity;
            }
        },
        removeQuantity: (state, action) => {
            const item = state.products.find(item => item.id === action.payload.id);
            if (item.quantity === 1) {
                item.quantity = 1;
            } else {
                item.quantity--;
            }
        },
        cleanCart: (state, action) => {
            state.products = []
        }
    },
})

// Action creators are generated for each case reducer function
export const { addToCart, removeFromCart, updateCart, cleanCart, addQuantity, removeQuantity } = cartReducer.actions

export default cartReducer.reducer