import React from "react";
import logo from "./../../Assets/Images/logotipo.svg";
const Logo = () => {
  return (
    <>
      <img src={logo} alt="" className="logo" />
    </>
  );
};

export default Logo;
