import React, { useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import LayoutFull from "../../Components/Layout/LayoutFull";
import LayoutAccount from "../../Components/Layout/LayoutAccount";
import AfiliatedAccounts from "../../Components/Account/AfiliatedAccounts";
import { Message } from "rsuite";
const Account = ({}) => {

	var url = new URL(window.location);
    const invitationAccepted = url.searchParams.get('invitation_accepted') === 'true';

	useEffect( () => {

	}, [])

	return (
		<LayoutAccount>
			{ invitationAccepted && (
				<Message showIcon type="success" header="Invitación aceptada" closable>
                    Invitación aceptada exitosamente.
                </Message>
			)}
			<div>
				<AfiliatedAccounts />
			</div>
		</LayoutAccount>
	)
};

export default Account;