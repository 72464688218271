const PayExtraPricesList = ({onChange}) => {
	return (
		<>
			<p>Valor cuota extraordinaria: </p>
		    <select className="form-control" id="valor-cuota-extraordinaria" name="valor_cuota_extraordinaria" onChange={onChange}>
		    	<option value="1000">1.000</option>
		    	<option value="2000">2.000</option>
		    	<option value="3000">3.000</option>
		    	<option value="5000">5.000</option>
		    	<option value="10000">10.000</option>
		    	<option value="20000">20.000</option>
		    	<option value="50000">50.000</option>
		    </select>
		</>
	)
}

export default PayExtraPricesList;