import { useEffect, useState } from "react";
import * as Api from "../../api";
import {Input, InputGroup, List, Message} from "rsuite";
import { formatRut } from "../../Service/Utils";

const ListAssociatedAccounts = ({selectable = false}) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getListAssociatedAccounts = async () => {
        const res = await Api.getAssociatedAccounts();
        setData(res.data);
        console.log(res.data);
    }

    useEffect( () => {
        getListAssociatedAccounts();
    }, []);

    const handleDelete = async (userId, index) => {

        if (!window.confirm('Confirmar eliminacion')) {
            return;
        }
        setLoading(true);
        const res = await Api.deleteAssociatedAccount(userId);
        setData(data.filter( item => userId !== item.id));
        setLoading(false);
    }

    return (
        <>
            <div className="row">
				<div className="col-12 col-md-12">
			        <h4><strong>Cuentas asociadas</strong></h4>
					<List bordered>
					{data.map((item, index) => (
					  <List.Item key={item.id} index={index} className="list-asociated d-flex justify-content-between">
					    <div className="" style={{width: '70%'}}>
                            { item.nombre_completo } <br />
                            { formatRut(item.rut) }
					    </div>
					    <div style={{width: '25%'}}>
					    	<button className="btn btn-danger" onClick={ e => handleDelete(item.id, index)} disabled={loading}>Eliminar asociado</button>
					    </div>
					  </List.Item>
					))}
					</List>
				</div>
			</div>
        </>
    )
}

export default ListAssociatedAccounts;