import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import LayoutFull from "../../Components/Layout/LayoutFull";
import axios from "axios";
import { Message, toaster } from "rsuite";
import LocalStorage from "./../../Service/LocalStorage";

const ValidateOne = () => {
  const history = useNavigate();
  const [file, setFile] = useState();
  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async (e) => {
    const formData = new FormData();
    formData.append("profile_picture", file);
    axios({
      url: `${process.env.REACT_APP_URL_API}socios/profile/upload-picture`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${LocalStorage.getToken().token}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        if (res?.url !== "") {
          toaster.push(
            <Message type="success">
              Se subió correctamente su foto de perfil.
            </Message>
          );
          setTimeout(() => {
            history("/validation/second");
          }, 3000);
          return;
        }
      }) // Handle the response from backend here
      .catch((err) => {
        console.info(err);
      }); // Catch errors if any
  };
  return (
    <LayoutFull>
      <div className="mt-3">
        <h2>Credencial Virtual</h2>
        <p>¿Deseas obtener tu credencial virtual? Sube tu foto aquí</p>
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Tu imagen de Credencial:</Card.Title>
            <Card.Text>
              <form>
                <img
                  src="https://mi.csdcolocolo.cl/img/user.png"
                  alt=""
                  className="m-2 img-default"
                />
                <input type="file" onChange={(e) => handleFile(e)} />
              </form>
            </Card.Text>
            <Card.Footer className="text-start">
              <p className="m-0">
                -. Agrega tu fotografía de rostro frontal. Se recomienda que la
                imagen tenga un tamaño de 400x400 pixeles, estar en formato PNG,
                JPG o GIF no animado y no pesar más de 2Mb.
              </p>
              <p className="m-0">
                -. Podrás adquirir tu credencial física solicitándola
                posteriormente.
              </p>
            </Card.Footer>
          </Card.Body>
        </Card>
        <div className="row mb-4">
          <div className="col-6">
            <Link to={"/validation/second"} className="btn bg-grey full">
              Omitir Paso
            </Link>
          </div>
          <div className="col-6">
            <button
              className="btn bg-black full"
              onClick={(e) => {
                handleUpload(e);
              }}
            >
              Guardar Foto
            </button>
          </div>
        </div>
      </div>
    </LayoutFull>
  );
};

export default ValidateOne;
