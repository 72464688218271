import { Loader, Message, Placeholder } from "rsuite";
import LayoutCanjes from "../../Components/Layout/LayoutCanjes";
import useCanjeDetails from "../../hooks/payments/useCanjeDetails";
import { formatNumeric } from '../../Service/Utils';

const CanjesVoucher = ({}) => {

    const buyOrder = new URLSearchParams(window.location.search).get("buy_order");
    
    const { canje, loadingCanje } = useCanjeDetails(buyOrder);

    const getTotalProductos = () => {
        let total = 0;

        if (canje) {
            canje.products.forEach ( item => {
                total += item.points;
            });
        }

        return total;
    }

    return (
        <LayoutCanjes>
            <div className="m-5" style={{minHeight:'400px'}}>
                {
                    loadingCanje && <Loader backdrop content="loading..." vertical />
                }
                
                {
                    canje && <>
                        <div>

                            <div className="alert alert-success">
                                <h5>
                                    <i className="bi bi-bookmark-check-fill"></i> Canje realizado exitosamente.
                                </h5>
                            </div>

                            
                            <div>
                                <h4>Datos del canje</h4>
                                <ul>
                                    <li>Nombre socio: {canje.nombre} {canje.apellido}</li>
                                    <li>N° de pedido: {canje.id}</li>
                                    <li>Comercio: Canjes CSD Colo Colo</li>
                                    <li>Puntos: {canje.amount_points}</li>
                                    {
                                        canje.wp_authorizationCode && <>
                                            <li>Código de autorización: {canje.wp_authorizationCode}</li>
                                            <li>Fecha trannsacción:{canje.created_at}</li>
                                            <li>Tipo pago: {canje.wp_paymentTypeCode}</li>
                                            <li>4 últimos digitos tarjeta: {canje.wp_cardNumber}</li>
                                        </>
                                    }
                                </ul>

                                <h4>Productos canjeados</h4>
                                <table className="table bordered">
                                    <thead>
                                        <tr>
                                            <th>Titulo</th>
                                            <th>Precio</th>
                                            <th colSpan={2}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            canje.products.map ( product => (
                                                <>
                                                    <tr key={product.id}>
                                                        <td>{product.title}</td>
                                                        <td>{product.points} Puntos</td>
                                                    </tr>
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>

                                <div className="d-flex justify-content-end border p-2">
                                    Total puntos gastados: { getTotalProductos() }
                                </div>
                            </div>
                        </div>
                    </>
                }

                {
                    !canje && <>
                        <div className="alert alert-danger">
                            Ha ocurrido un problema con el pago por favor intente nuevamente.
                        </div>
                    </>
                }
            </div>
        </LayoutCanjes>
    )
}

export default CanjesVoucher;