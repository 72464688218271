import { Form, Message, Schema, toaster } from "rsuite";

const TextField = ({ name, label, type, accepter, ...rest }) => (
	<>
		<Form.Group controlId={name} className="mb-3">
			<Form.ControlLabel>{label}</Form.ControlLabel>
			<Form.Control name={name} accepter={accepter} {...rest} type={type} />
		</Form.Group>
	</>
);

export default TextField;