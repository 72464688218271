import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Content, Sidenav, Nav } from "rsuite";
import Footer from "../Footer/Footer";
import HeaderComponent from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import * as Api from "../../api";
import LocalStorage from "./../../Service/LocalStorage";
import './LayoutAccount.scss';
import usePoints from "../../hooks/account/usePoints";

const LayoutAccount = ({ children }) => {

	const [activeMenu, setActiveMenu] = useState('');
    const { points } = usePoints();

	const history = useNavigate();
	const getInfo = async () => {
	if (LocalStorage.getToken().token !== null) {
	  if (parseInt(LocalStorage.getStatus().status) === 1) {
	    const response = await Api.getInfoSocio();
	    LocalStorage.setInformations(response.data);
	    LocalStorage.setStatus(0);
	  }
	} else {
	  history("/");
	}
	};
	getInfo();

	useEffect ( () => {
		var url = new URL(window.location);
		const menu = url.searchParams.get('menu');

		setActiveMenu(menu);
	});
	return (
	<>
		<HeaderComponent show={true} />
		<Content className={"mt-5 pt-5"}>
            <Container className="white my-4 border shadow">
                <Row>
                    <Col xs={12} md={12} className="border-end">
                    <div className="alert alert-primary text-center" role="alert">
                        Tienes <strong>{ points }</strong> puntos acumulados para canjear.
                    </div>
                    </Col>
                </Row>
            </Container>
			<Container className="white my-4 border shadow">
				<Row>
					<Col xs={12} md={4} className="border-end">
						<ul className="list-group">
							<li className={`list-group-item menu-item ${activeMenu === 'associate-accounts' && 'active-menu'}`} aria-current="true">
						  		<Link to={`/my-account?menu=associate-accounts`}>
				                    Cuentas asociadas
				                </Link>
						  	</li>
                            <li className={`list-group-item menu-item ${activeMenu === 'invite-accounts' && 'active-menu'}`} aria-current="true">
						  		<Link to={`/invite-new-partner?menu=invite-accounts`}>
				                    Invita a ser socio
				                </Link>
						  	</li>
							<li className={`list-group-item menu-item ${activeMenu === 'pagos-automaticos' && 'active-menu'}`} aria-current="true">
						  		<Link to={`/pagos/automaticos?menu=pagos-automaticos`}>
				                    Pagos automaticos
				                </Link>
						  	</li>
							<li className={`list-group-item menu-item ${activeMenu === 'payments-history' && 'active-menu'}`} aria-current="true">
								<Link to={`/account/payments/history?menu=payments-history`}>
				                    Historial de pagos
				                </Link>
							</li>
						  	<li className={`list-group-item menu-item ${activeMenu === 'purchases-history' && 'active-menu'}`} aria-current="true">
						  		<Link to={`/account/purchases/history?menu=purchases-history`}>
				                    Historial de compras
				                </Link>
						  	</li>
						</ul>
					</Col>
					<Col xs={12} md={8}>
					  {children}
					</Col>
				</Row>
			</Container>
		</Content>
		<Footer />
	</>
	);
};

export default LayoutAccount;
