import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "rsuite";
import Logo from "../Logo/Logo";
import LocalStorage from "../../Service/LocalStorage";
import * as Api from "../../api";
import { useSelector } from "react-redux";

const HeaderComponent = ({ show }) => {
  const history = useNavigate();
  const info = LocalStorage?.getInformations().data;
  const handleClose = () => {
    history("/");
    LocalStorage.close();
  };

  const [fullName, setFullName] = useState('');
  
  const getName = async () => {
  	const res = await Api.getProfileData();
	setFullName(res.data.nombre_completo);
  }

  useEffect( () => {
	getName();
  }, []);

  return (
    <>
      <Navbar bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand>
            <a href="https://mi.csdcolocolo.cl/">
              <Logo />
            </a>
          </Navbar.Brand>
          {show && (
            <Nav className="me-auto">
              <Nav>
                <Link to="/dashboard" className="nav-link">
                  Principal
                </Link>
              </Nav>
              <Nav>
                <Link to="/my-profile" className="nav-link">
                  Perfil
                </Link>
              </Nav>
              <Nav>
                <Link to="/credencial" className="nav-link">
                  Credencial
                </Link>
              </Nav>
              <Nav>
                <Link to="/my-account" className="nav-link">
                  Mi cuenta
                </Link>
              </Nav>
              <Nav>
                <Link to="/canjes" className="nav-link">
                  Canjes
                </Link>
              </Nav>
			  <Nav>
              <Link to="/account/payments/history?menu=payments-history" className="nav-link">
                  Historial de pagos
              </Link>
              </Nav>
			  <Nav>
                <Link to="/account/purchases/history?menu=purchases-history" className="nav-link">
                  Mis compras
                </Link>
              </Nav>
              <Nav>
                <a href="https://mi.csdcolocolo.cl/tienda" className="nav-link">
                  Tienda
                </a>
              </Nav>
              <Nav>
                <Link to="/pagos/automaticos?menu=pagos-automaticos" className="nav-link">
                  Pagos automaticos
                </Link>
              </Nav>
            </Nav>
          )}
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {info?.imagen !== null && (
            <Avatar
              size="lg"
              circle
              src={`${process.env.REACT_APP_URL_S3}${info?.imagen}`}
              className="me-2"
            />
            )}
            <Navbar.Text>
              <p className="m-0">
                Bienvenido <a>{fullName}</a>
              </p>
              <p className="m-0 close-sesion" onClick={() => handleClose()}>
                Cerrar Sesión
              </p>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderComponent;
