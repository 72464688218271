import { useLocation } from "react-router-dom";
import { renderToString } from "react-dom/server";
import ReactHtmlParser from "html-react-parser";

const PayQuantitiesList = ({handleInput, minCuotas, titleSelect = 'Número de cuotas a pagar', defaultValue = 0}) => {

	const location = useLocation();

	const OptionSelect = () => {
		let html = "<option value='0'>Seleccione número de cuotas</option>";
		let min = location.state?.type_pay === "incorporacion" ? minCuotas : 1;
		for (let step = min; step < 25; step++) {
		  html += renderToString(<option value={step} selected={step == defaultValue}>{step}</option>);
		}
		return ReactHtmlParser(renderToString(html));
	}

	return (
		<>
		    <p>{titleSelect}</p>
			<select
              onChange={handleInput}
              name="numero_cuotas"
              className="form-control"
            >
              {ReactHtmlParser(OptionSelect())}
            </select>
		</>
	)
}

export default PayQuantitiesList;