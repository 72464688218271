import React, { useState, useEffect, createContext } from "react";
import { renderToString } from "react-dom/server";
import { useLocation, Link } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Layout from "../../Components/Layout/Layout";
import LayoutFull from "../../Components/Layout/LayoutFull";
import * as Api from "../../api";
import { formatRut, formatDate, formatNumeric, getEdadCuota, getMonto } from "../../Service/Utils";
import ReactHtmlParser from "html-react-parser";
import LocalStorage from "./../../Service/LocalStorage";
import { Radio, RadioGroup, Message, Panel, Paragraph, toaster, Notification, List } from 'rsuite';
import CreditCard from "../../Components/Payment/CreditCard";
import AssociatedAccounts from "../../Components/Account/AssociatedAccounts";
import ListAssociatedAccounts from "../../Components/Account/ListAssociatedAccounts";
import { Tag } from 'rsuite';
import PayExtraPricesList from "./PayExtraPricesList";
import PayQuantitiesList from "./PayQuantitiesList";

const Normal = () => {

	const [paymentMethod, setPaymentMethod] = useState('');
	const [showOneClickInfo, setShowOneClickInfo] = useState(false);
	const [urlParams, setUrlParams] = useState();
	const [hasOneclickSub, setHasOneclickSub] = useState(false);
	const [oneclickSub, setOneclickSub] = useState({});
	const [active, setActive] = useState(true);
	const [url, setUrl] = useState();
	const [payableAccounts, setPayableAccounts] = useState([]);
	const location = useLocation();
	const [errorOneClickPayment, setErrorOneClickPayment] = useState('');
	const [typeCuota, setTypeCuota] = useState('normal');
	const [valorCuotaExtraordinaria, setValorCuotaExtraordinaria] = useState(1000);

	const [minCuotas, setMinCuotas] = useState(0);

	const getAmount = async () => {

		console.log("Ejecutando: ");

		const res = await Api.getCuotasPendientes();

		setMinCuotas(res.min_cuotas);

		setInputs({
		  ...inputs,
		  ['valor']: res.valor_cuota,
		  ['min_cuota']: res.min_cuotas
		});

		return {
			incorporacion: res.incorporacion,
		    cuota:  res.valor_cuota,
		    minnumerocuota:  res.min_cuotas,
		    numcuota:  res.numero_cuotas,
		};
	};

	// getMonto(getEdadCuota(partner.fecha_nacimiento), partner.region)
	const getValorCuotaForPartner = (partner) => getMonto(getEdadCuota(partner.fecha_nacimiento), partner.region, partner);

	const [info, setInfo] = useState([]);

	const mainAccountId = info.id;

	const [inputs, setInputs] = useState({
		valor: 0,
		min_cuota: 0,
		numero_cuotas: 0,
		buyOrder: 123456789,
		tipo_pago: typeCuota === 'normal' ? 'cuotas' : 'extraordinaria',
		valor_cuota_extraordinaria: 0
	});

	useEffect( () => {
		getAmount();
	}, []);

	useEffect(() => {
	  	setInfo(LocalStorage.getInformations().data);
	}, [inputs]);

  	const [ws_token, setToken] = useState();

	const handleRadio = (name, value) => {
		setPaymentMethod(value);
		setShowOneClickInfo(value == 'oneclick');
		setInputs({
		  ...inputs,
		  [name]: paymentMethod
		});
	}

	const handleInput = (e) => {
		setInputs({
		  ...inputs,
		  [e.target.name]: !parseInt(e.target.value) ? 0 : parseInt(e.target.value),
		});
	};

	const [selectedAccounts, setSelectedAccounts] = useState([]);
		const addAccount = (event) => {
		let newList = [...selectedAccounts];

		if (event.target.checked) {
			newList = [...selectedAccounts, event.target.value];
		} else {
			newList.splice(selectedAccounts.indexOf(parseInt(event.target.value)), 1);
		}


		setSelectedAccounts(newList);

		setInputs({...inputs, selectedAccounts: newList});
	}

	const handlePay = async (e) => {

		setActive(false);

		let formData = inputs;
		formData.tipo_pago = typeCuota === 'normal' ? 'cuotas' : 'extraordinaria';
		formData.valor_cuota_extraordinaria = valorCuotaExtraordinaria;

		if (paymentMethod == 'oneclick' && hasOneclickSub) {
			const res = await Api.PayCuotaNormalOneClick(formData);
			await updateUserInfo();

			if (!res.status) {
				setErrorOneClickPayment(res.data);
				setActive(true);
			}

			if (res.status) window.location.href = `/payments/final?id_compra=${res.id_compra}`;

			return;
		}

		const data = await requestPaymentToken(formData);

		setUrl(data.url);
		setToken(data.ws_token);
		LocalStorage.setStatus(1);
		setTimeout(function() {
            document.getElementById("form_webpay").submit();
        }, 200);
	};

	const requestPaymentToken = async (formData) => {

		if (paymentMethod == 'oneclick') {
		  	return await Api.SubscribeOneClick(formData);
		}

		let data;

		if (formData.tipo_pago === 'cuotas') {
			data = await Api.PayCuotaNormal(formData);
		} else {
			data = await Api.PayCuotaExtra(formData);
		}

		await updateUserInfo();

		return data;
	}

	const updateUserInfo = async () => {
		const response = await Api.getInfoSocio();
		LocalStorage.setInformations(response.data);
	}

	const onDelete = () => {
		setShowOneClickInfo(true);
		setHasOneclickSub(false);
		setOneclickSub(null);
	}

	useEffect(() => {

		var url = new URL(window.location);
		const pmethod = url.searchParams.get('payment_method');
		const status = url.searchParams.get('status_oneclick');
		const tipoCuota = url.searchParams.get('type_cuota');

		if (typeCuota === 'regularizacion') {
			
			window.location.href = '/pago/regularizacion?type_cuota=regularizacion';
			return;
		}

		setTypeCuota(tipoCuota);
		setPaymentMethod(pmethod);
		setShowOneClickInfo(true);
		setUrlParams({payment_method: pmethod, status: status});

		const getOneclickSubscription = async () => {
		  const res = await Api.getOneclickSubscription();
		  if (res.data && res.status && res.data.user) {
		    setHasOneclickSub(true);
		    setOneclickSub(res.data);
		  }
		  console.log(res);
		}

		getOneclickSubscription();
		console.log(paymentMethod);
	}, [])

	const getPayableAccounts = async () => {
		const res = await Api.getAssociatedAccounts();
		setPayableAccounts(res.data);
		console.log(res.data);
	}

	useEffect( () => {
	  	getPayableAccounts();
	}, []);

	const getTotalForAccounts = () => {

		let sumaTotal = 0;

		selectedAccounts.forEach((accountId) => {

		  const current = payableAccounts.find( (item) => item.id == accountId)

		  //console.log("ACA1: ", current, accountId, selectedAccounts);
		  const valorCuota = getValorCuotaForPartner(current).cuota;

		  sumaTotal += valorCuota;
		})

		return sumaTotal;
	}

	const getFullTotal = () => {
		const valorCuota = typeCuota === 'normal' ? inputs.valor : valorCuotaExtraordinaria;

		const totalSocio = valorCuota * parseInt(inputs.numero_cuotas);

		const totalCuentasAsociadas = getTotalForAccounts() * parseInt(inputs.numero_cuotas);

		return formatNumeric(totalSocio + totalCuentasAsociadas);
	}

	const getValorCuota = () => typeCuota === 'normal' ? inputs.valor : valorCuotaExtraordinaria;

 	const updateValorCuotaExtra = (e) => {
 		setValorCuotaExtraordinaria(e.target.value)
 	}
  
  return (
    <LayoutFull>
        <form name="form_webpay" action={url} method="POST" id="form_webpay">
        {
          paymentMethod == 'webpay' ? 
          <>
            <input type="hidden" name="token_ws" value={ws_token} />
          </> : <>
            <input type="hidden" name="TBK_TOKEN" value={ws_token} />
          </>
        }
      </form>
      <Container className="my-4">
        {
          urlParams?.status && hasOneclickSub ? 
          <>
            <Row>
              <Col xs={12} md={12}>
                    <Message showIcon type="success">
                      <h6>Tu tarjeta ha sido inscrita exitosamente.</h6>
                      CSD Colo-Colo no almacena los datos privados de tu tarjeta. Dicha información solo será almacenada por Transbank de forma segura.<br />
                    </Message>
              </Col>
            </Row>
          </> : <></>
        }
        <Row>
          <Col xs={12} md={6}>
            <h2 className="title">
              <strong>
              	{ typeCuota === 'normal' ? 'Pago de cuotas' : 'Cuotas extraordinarias'}

              </strong>
            </h2>
            <Row>
          		<Col>
          			{
		            	typeCuota === 'extraordinaria' && (

							<Message showIcon type="info" header="Cuotas extraordinarias">
								<p>Hay socios y socias que tienen la posibilidad y el deseo de aportar un monto adicional para apoyar al club en el desarrollo de sus actividades. El pago de cuotas extraordinarias es un mecanismo a través del cual se pueden canalizar y registrar estos aportes adicionales a nuestro Club.</p>
								<p>Las cuotas extraordinarias son una figura que ha estado siempre presente en los estatutos del club, pero sólo recientemente ha sido incorporada a nuestra plataforma web. Los montos recaudados por este concepto pueden ser utilizados para los fines que los propios socios determinen mediante una asamblea extraordinaria.</p>
								<p>Es importante aclarar que este aporte es de carácter absolutamente voluntario. Cada socio/a puede determinar qué monto aporta y en qué tiempos lo realiza; puede hacer un aporte constante de manera mensual, realizarlo cada cierto tiempo, o incluso por una única vez.</p>
								<p>Además, es relevante informar que el registro de cuotas extraordinarias NO se relaciona con las cuotas normales pagadas por cada socio.</p>
							</Message>
		           	)}
          		</Col>
          	</Row>

            { typeCuota === 'normal' && <p>Valor cuota: ${formatNumeric(inputs.valor)}</p> }

            { typeCuota === 'normal'  && <PayQuantitiesList handleInput={handleInput} minCuotas={minCuotas} /> }

            {
                <>
                    <Row>
                        <Col xs={12} md={12}>
                            {
                            	typeCuota === 'normal' && <>

                            		<br />

									<ul className="list-group">
		                                <li className="item-account list-group-item d-flex justify-content-between align-items-start">
		                                    <div className="ms-2 me-auto">
		                                        <div className="">
		                                            <input type="checkbox" 
		                                                    name="associated_account" 
		                                                    value={inputs.main_account} 
		                                                    id="my_account" 
		                                                    checked={true}
		                                                    disabled={true} />
		                                            <label htmlFor="my_account" style={{ marginLeft: '10px'}}>Mi Cuenta</label>
		                                        </div>
		                                        <span className="semi-bold">Al dia hasta:</span> <span>{formatDate(info?.al_dia_hasta)}</span>
		                                    </div>
		                                </li>
		                            </ul>
                            		<hr />
		                            <p>Elija cuenta asociada a pagar: </p>
		                            <List bordered>
		                              {payableAccounts.map((item, index) => (
		                                <List.Item key={item.id} index={index} className="list-asociated d-flex justify-content-between">

		                                  <div className="" style={{width: '80%'}}>
											{ (item.al_dia_hasta == null || item.al_dia_hasta == "") && (
												<p>
													<Tag color="red">Debe pagar incorporación</Tag>
													<br />
													<span className="small">El socio debe realizar pago de inscripción. Luego podrás realizar el pago de su mensualidad.</span>
												</p>
											) }
		                                      <p>
		                                      </p>
		                                      <input type="checkbox" 
		                                              name="associated_account" 
		                                              id={`acccount-${item.id}`}
		                                              value={item.id}
		                                              disabled={!item.al_dia_hasta}
		                                              onChange={addAccount} />
		                                      <label htmlFor={`acccount-${item.id}`} style={{ marginLeft: '10px'}}>{ item.nombre_completo }</label> <br />
		                                      { formatRut(item.rut) } <br />
		                                      <span className="semi-bold">Al dia hasta:</span>
		                                      <span>{formatDate(item.al_dia_hasta)}</span>
		                                      <br />
		                                      <span className="semi-bold">Valor cuota: ${getValorCuotaForPartner(item)?.cuota}</span>
		                                  </div>
		                                </List.Item>
		                              ))}
		                            </List>
                            	</>
                            }
                        </Col>
                    </Row>
                </>
            }
          </Col>
          <Col xs={12} md={6}>
          	
          	<Row>
          		<Col>
          		    { typeCuota === 'extraordinaria'  && <PayQuantitiesList handleInput={handleInput} minCuotas={minCuotas} /> }
          		    <br />
          			{ typeCuota === 'extraordinaria' && <PayExtraPricesList onChange={updateValorCuotaExtra} /> }
          		</Col>
          	</Row>

          	<hr />

            <table className="table border">
              <tbody>
                {typeCuota === 'normal' && location.state?.number && location.state?.number > 0 ? (
                  <tr>
                    <td>Cuotas Pendientes</td>
                    <td align="right">{location.state?.number}</td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <td>Cuotas Seleccionadas</td>
                  <td align="right">{inputs.numero_cuotas}</td>
                </tr>
                <tr className="semi-bold">
                  <td>Total socio</td>
                  <td align="right">
                    $
                    {formatNumeric(getValorCuota() * parseInt(inputs.numero_cuotas))} <br />
                  </td>
                </tr>
                {
                  selectedAccounts.length > 0 ? 
                  <>
                    <tr className="semi-bold">
                      <td>Total cuentas asociadas</td>
                      <td align="right">
                        $
                        {formatNumeric(getTotalForAccounts() * parseInt(inputs.numero_cuotas))} <br />
                      </td>
                    </tr>
                  </> : <></>
                }
                <tr className="semi-bold">
                  <td>Total</td>
                  <td align="right">
                    $
                    {getFullTotal()} <br />
                  </td>
                </tr>
                <tr className="semi-bold">
                  <td>Metodo de pago</td>
                </tr>
                <tr>
                  <td>
                    <RadioGroup name="metodo_pago" inline value={paymentMethod} onChange={value => { handleRadio ('metodo_pago', value)}}>
                      <Radio value="webpay">Webpay</Radio>
                      <Radio value="oneclick">OneClick</Radio>
                    </RadioGroup>
                  </td>
                </tr>
                { paymentMethod == 'oneclick' && showOneClickInfo && hasOneclickSub ? 
                  <>
                    <tr>
                      <td>
                        <CreditCard subscription={oneclickSub} onDelete={onDelete} />
                      </td>
                    </tr>
                  </> : 
                  <></>
                }
                { paymentMethod == 'oneclick' && showOneClickInfo && !hasOneclickSub ? 
                  <>
                    <tr>
                      <td>
                        <Message showIcon type="success" header="OneClick">
                          <ul>
                            <li>Para utilizar este medio de pago, debes inscribir una tarjeta de débito o crédito, trámite que deberás realizar una sola vez. </li>
                            <li>Esto es lo mismo que ingresar los datos de tu tarjeta cuando vas a pagar mensualmente tus cuotas, con la diferencia de que dicha información quedará registrada, y podrás utilizar dicha tarjeta cada vez que lo desees, sin necesidad de ingresar los datos cada vez. </li>
                            <li>CSyD Colo-Colo no almacena los datos privados de tu tarjeta. Dicha información solo será almacenada por Transbank de forma segura. </li>
                            <li>No se realizará ningún cobro no autorizado a tu tarjeta.</li>
                          </ul>
                        </Message>
                      </td>
                    </tr>
                  </> : ''
                }
              </tbody>
            </table>
            <div className="text-center">

            	{
            		errorOneClickPayment && (
        			
        			<>
            			<span>{errorOneClickPayment}</span>
            			<br />
        			</>
            	)}
              {active ? (
              	<>
	                <button
	                  onClick={(e) => {
	                    handlePay(e);
	                  }}
	                  className="btn bg-red"
	                  disabled={ inputs.numero_cuotas < 1 }
	                >
	                  { paymentMethod == 'webpay' || hasOneclickSub ? 'Pagar' : 'Inscribir tarjeta' }
	                </button>

	                <p>
						Para que no tengas que recordar pagar mes a mes, 
						te invitamos a automatizar tus pagos y asegurarte nunca quedar moroso/a. 
						
						<Link to="/pagos/automaticos?menu=pagos-automaticos" className="nav-link">
		                  ¡Haz click AQUÍ!
		                </Link>
	                </p>
                </>
              ) : (
                <>
                  <Spinner animation="grow" className="mt-3" />
                  <p className="semi-bold">Redireccionando...</p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </LayoutFull>
  );
};

export default Normal;
