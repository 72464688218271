import React from "react";
import Layout from "../../Components/Layout/Layout";
import LocalStorage from "./../../Service/LocalStorage";
import SocioError from "./ErrorMap/SocioError";
import Home from "./Home";

const Dashboard = () => {
  //estado = difunto = 3
  //estado = eliminado = 2
  //estado = renuncia = 1
  //armar array

  const info = LocalStorage.getInformations().data;
  const ValidateStatus = (info) => {
    const { activo, estado } = info;
    if (estado === 0 && activo === 1) {
      return <Home />;
    } else if (estado !== 0) {
      return <SocioError status={estado} />;
    }
  };
  return <Layout>{ValidateStatus(info)}</Layout>;
};

export default Dashboard;
