import { useEffect, useState } from "react";
import LayoutAccount from "../../Components/Layout/LayoutAccount";
import ListPaymentHistory from "../../Components/Account/ListPaymentHistory";
import * as Api from "../../api";
import { Link, useNavigate } from "react-router-dom";
import { formatRut, formatDate, formatNumeric, getEdadCuota, getMonto } from "../../Service/Utils";

const PurchaseHistory = () => {

	const [purchases, setPurchases] = useState([]);

	const getPurchasesHistory = async () => {
		const res = await Api.getPurchasesHistory();

		setPurchases(res.data);
	}

	useEffect( () => {
		getPurchasesHistory();
	}, []);

	return (
		<LayoutAccount>
			<div className="p-3">
				<h4>Historial de compras</h4>
				<table className="table">
					<thead>
						<tr>
						  <th>Nº</th>
						  <th>Fecha</th>
						  <th>Estado</th>
						  <th>Monto</th>
						  <th>Acciones</th>
						</tr>
					</thead>
					<tbody>
					{
						purchases.length && purchases.map ( item => (
							<tr>
								<th>{item.id}</th>
								<th>{item.fecha}</th>
								<th>{item.estado === 2 ? 'PAGADA' : 'NO PAGADA'}</th>
								<th>${formatNumeric(item.total)}</th>
								<th>
									<a href={item.url_voucher}>Voucher</a>
								</th>
						    </tr>
						))
					}
					</tbody>
				</table>
			</div>
		</LayoutAccount>
	)
};

export default PurchaseHistory;