import React, { useState, useEffect, createContext } from "react";
import { renderToString } from "react-dom/server";
import { useLocation } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Layout from "../../Components/Layout/Layout";
import LayoutFull from "../../Components/Layout/LayoutFull";
import * as Api from "../../api";
import { formatRut, formatDate, formatNumeric, getEdadCuota, getMonto } from "../../Service/Utils";
import ReactHtmlParser from "html-react-parser";
import LocalStorage from "./../../Service/LocalStorage";
import { Radio, RadioGroup, Message, Panel, Paragraph, toaster, Notification, List } from 'rsuite';
import CreditCard from "../../Components/Payment/CreditCard";
import AssociatedAccounts from "../../Components/Account/AssociatedAccounts";
import ListAssociatedAccounts from "../../Components/Account/ListAssociatedAccounts";
import { Tag } from 'rsuite';

const Regularizacion = () => {

	const [paymentMethod, setPaymentMethod] = useState('');
	const [showOneClickInfo, setShowOneClickInfo] = useState(false);
	const [urlParams, setUrlParams] = useState();
	const [hasOneclickSub, setHasOneclickSub] = useState(false);
	const [oneclickSub, setOneclickSub] = useState({});
	const [active, setActive] = useState(true);
	const [url, setUrl] = useState();
	const [payableAccounts, setPayableAccounts] = useState([]);
	const location = useLocation();
	const [errorOneClickPayment, setErrorOneClickPayment] = useState('');
	const [typeCuota, setTypeCuota] = useState('normal');
	const [valorCuotaExtraordinaria, setValorCuotaExtraordinaria] = useState(1000);
	const [pendingAmount, setPendingAmount] = useState(0);
	const [equivalenciaCuotas, setEquivalenciaCuotas] = useState(0);

	const [n_cuotas, setNumero] = useState(0);

	const getAmount = () => {
		return getMonto(
			getEdadCuota(LocalStorage.getInformations().data.fecha_nacimiento),
			LocalStorage.getInformations().data.region
		);
	};
	// getMonto(getEdadCuota(partner.fecha_nacimiento), partner.region)
	const getValorCuotaForPartner = (partner) => getMonto(getEdadCuota(partner.fecha_nacimiento), partner.region, partner);

	const [info, setInfo] = useState([]);

	const mainAccountId = info.id;

	const [inputs, setInputs] = useState({
		valor: getAmount().cuota,
		min_cuota: getAmount().minnumerocuota,
		numero_cuotas: 0,
		buyOrder: 123456789,
		tipo_pago: typeCuota === 'normal' ? 'cuotas' : 'extraordinaria',
		valor_cuota_extraordinaria: 0
	});

	useEffect(() => {
	  	setInfo(LocalStorage.getInformations().data);
	}, [inputs]);

  	const [ws_token, setToken] = useState();

	const handleRadio = (name, value) => {
		console.log("Radio: " + value);
		setPaymentMethod(value);

		setShowOneClickInfo(value == 'oneclick');

		setInputs({
		  ...inputs,
		  [name]: paymentMethod
		});
	}
	const handleInput = (e) => {
		setInputs({
		  ...inputs,
		  [e.target.name]: !parseInt(e.target.value) ? 0 : parseInt(e.target.value),
		});
	};

	const handlePay = async (e) => {

		setActive(false);

		if (paymentMethod == 'oneclick' && hasOneclickSub) {
			const res = await Api.PayCuotaNormalOneClick({numero_cuotas: equivalenciaCuotas, tipo_pago: 'regularizacion'});

			await updateUserInfo();

			if (!res.status) {
				setErrorOneClickPayment(res.data);
				setActive(true);
			}

			if (res.status) window.location.href = `/payments/final?id_compra=${res.id_compra}`;

			return;
		}

		const data = await requestPaymentToken();

		setUrl(data.url);
		setToken(data.ws_token);
		LocalStorage.setStatus(1);
		document.getElementById("form_webpay").submit();
	};

	const requestPaymentToken = async () => {

		if (paymentMethod == 'oneclick') {
		  	return await Api.SubscribeOneClick({tipo_pago: 'regularizacion'});
		}

		const data = await Api.PayRegularizacion();

		await updateUserInfo();

		return data;
	}

	const updateUserInfo = async () => {
		const response = await Api.getInfoSocio();
		LocalStorage.setInformations(response.data);
	}

	const onDelete = () => {
		setShowOneClickInfo(true);
		setHasOneclickSub(false);
		setOneclickSub(null);
	}

	useEffect(() => {

		var url = new URL(window.location);
		const pmethod = url.searchParams.get('payment_method');
		const status = url.searchParams.get('status_oneclick');
		const tipoCuota = url.searchParams.get('type_cuota');
		setTypeCuota(tipoCuota);
		setPaymentMethod(pmethod);
		setShowOneClickInfo(true);
		setUrlParams({payment_method: pmethod, status: status});

		const getOneclickSubscription = async () => {
		  const res = await Api.getOneclickSubscription();
		  if (res.data && res.status && res.data.user) {
		    setHasOneclickSub(true);
		    setOneclickSub(res.data);
		  }
		  console.log(res);
		}

		getOneclickSubscription();
		console.log(paymentMethod);
	}, [])

	const getNumeroCuotas = async (e) => {
		const response = await Api.getCuotasPendientes({
			rut: info?.rut,
		});
		setNumero(response.cuotas_pendientes);
	};

	const getPendingAmount = async () => {
		const res = await Api.getRegularizationPendingAmount();
		setPendingAmount(res.data.total);
		setEquivalenciaCuotas(res.data.cuotas_equivalentes);
	}

	useEffect( () => {
	  	getNumeroCuotas();
	  	getPendingAmount();
	}, []);

	const getValorCuota = () => typeCuota === 'normal' ? inputs.valor : valorCuotaExtraordinaria;

 	const updateValorCuotaExtra = (e) => {
 		setValorCuotaExtraordinaria(e.target.value)
 	}
  
  return (
    <LayoutFull>
        <form name="form_webpay" action={url} method="POST" id="form_webpay">
        {
          paymentMethod == 'webpay' ? 
          <>
            <input type="hidden" name="token_ws" value={ws_token} />
          </> : <>
            <input type="hidden" name="TBK_TOKEN" value={ws_token} />
          </>
        }
      </form>
      <Container className="my-4">
        {
          urlParams?.status && hasOneclickSub ? 
          <>
            <Row>
              <Col xs={12} md={12}>
                    <Message showIcon type="success">
                      <h6>Tu tarjeta ha sido inscrita exitosamente.</h6>
                      CSD Colo-Colo no almacena los datos privados de tu tarjeta. Dicha información solo será almacenada por Transbank de forma segura.<br />
                    </Message>
              </Col>
            </Row>
          </> : <></>
        }
        <Row>
          <Col xs={12} md={12}>
            <h2 className="title">
              <strong>
              	Regularización
              </strong>
            </h2>
            <p>
				<span className="red">
					{formatNumeric(n_cuotas)} cuotas pendientes
				</span>
            </p>

{/*			<p>
            	<span className="red" style={{fontWeight: '600'}}>
            		- Sistema de regularización válido por socio para una vez cada cinco años.
            	</span>
            	<br/>
            	<span className="red" style={{fontWeight: '600'}}>
            		- El socio que acepta este sistema de regularización de cuotas sociales queda inhabilitado para postular a ser socio honorario.
            	</span>
            </p>
*/}

	
			<Message showIcon type="info" header="Importante">
				Si debes 24 o más cuotas, puedes regularizar tu situación pagando el 50% de la deuda. 
				Esto lo puedes hacer sólo una vez cada 5 años, y al hacerlo quedas inhabilitado de optar a ser socio honorario luego de 30 años como socio.
			</Message>
{/*            <p>Valor cuota: ${formatNumeric(inputs.valor)}</p>
            <p style={{fontWeight: '600'}}>Paga hasta un 50% y quedas al día</p>
*/}

			<table className="table border">
              <tbody>
                {location.state?.number && location.state?.number > 0 ? (
                  <tr>
                    <td>Cuotas Pendientes</td>
                    <td align="right">{location.state?.number}</td>
                  </tr>
                ) : (
                  ""
                )}
                <tr className="semi-bold">
                  <td>Total</td>
                  <td align="right">
                    $ {formatNumeric(pendingAmount)} <br /> (Equivalente a {equivalenciaCuotas} cuotas)
                  </td>
                </tr>
                <tr className="semi-bold">
                  <td>Metodo de pago</td>
                </tr>
                <tr>
                  <td>
                    <RadioGroup name="metodo_pago" inline value={paymentMethod} onChange={value => { handleRadio ('metodo_pago', value)}}>
                      <Radio value="webpay">Webpay</Radio>
                      <Radio value="oneclick">OneClick</Radio>
                    </RadioGroup>
                  </td>
                </tr>
                { paymentMethod == 'oneclick' && showOneClickInfo && hasOneclickSub ? 
                  <>
                    <tr>
                      <td>
                        <CreditCard subscription={oneclickSub} onDelete={onDelete} />
                      </td>
                    </tr>
                  </> : 
                  <></>
                }
                { paymentMethod == 'oneclick' && showOneClickInfo && !hasOneclickSub ? 
                  <>
                    <tr>
                      <td>
                        <Message showIcon type="success" header="OneClick">
                          <ul>
                            <li>Para utilizar este medio de pago, debes inscribir una tarjeta de débito o crédito, trámite que deberás realizar una sola vez. </li>
                            <li>Esto es lo mismo que ingresar los datos de tu tarjeta cuando vas a pagar mensualmente tus cuotas, con la diferencia de que dicha información quedará registrada, y podrás utilizar dicha tarjeta cada vez que lo desees, sin necesidad de ingresar los datos cada vez. </li>
                            <li>CSyD Colo-Colo no almacena los datos privados de tu tarjeta. Dicha información solo será almacenada por Transbank de forma segura. </li>
                            <li>No se realizará ningún cobro no autorizado a tu tarjeta.</li>
                          </ul>
                        </Message>
                      </td>
                    </tr>
                  </> : ''
                }
              </tbody>
            </table>
            <div className="text-center">

            	{
            		errorOneClickPayment && (
        			
        			<>
            			<span>{errorOneClickPayment}</span>
            			<br />
        			</>
            	)}
              {active ? (
                <button
                  onClick={(e) => {
                    handlePay(e);
                  }}
                  className="btn bg-red"
                >
                  { paymentMethod == 'webpay' || hasOneclickSub ? 'Pagar' : 'Inscribir tarjeta' }
                </button>
              ) : (
                <>
                  <Spinner animation="grow" className="mt-3" />
                  <p className="semi-bold">Redireccionando...</p>
                </>
              )}
            </div>


          </Col>
        </Row>
      </Container>
    </LayoutFull>
  );
};

export default Regularizacion;
