import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row, Nav, Navbar } from "react-bootstrap";
import { Content, Sidenav } from "rsuite";
import Footer from "../Footer/Footer";
import * as Api from "../../api";
import LocalStorage from "./../../Service/LocalStorage";
import Logo from "../Logo/Logo";
import UserInfoBanner from '../User/UserInfoBanner';
import { useSelector } from 'react-redux'

const LayoutCanjes = ({ children }) => {

    const cart = useSelector((state) => state.cartReducer.products);
	const [activeMenu, setActiveMenu] = useState('');
    const info = LocalStorage?.getInformations().data;
	const history = useNavigate();
	const getInfo = async () => {
	if (LocalStorage.getToken().token !== null) {
	  if (parseInt(LocalStorage.getStatus().status) === 1) {
	    const response = await Api.getInfoSocio();
	    LocalStorage.setInformations(response.data);
	    LocalStorage.setStatus(0);
	  }
	} else {
	  history("/");
	}
	};
	getInfo();

	useEffect ( () => {
		var url = new URL(window.location);
		const menu = url.searchParams.get('menu');

		setActiveMenu(menu);
        
	}, []);


	return (
	<>
    <Navbar bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand>
            <a href="https://mi.csdcolocolo.cl/">
              <Logo />
            </a>
          </Navbar.Brand>
          <Nav className="me-auto">
              <Nav>
                <Link to="/dashboard" className="nav-link">
                  Principal
                </Link>
              </Nav>
              <Nav>
                <Link to="/my-profile" className="nav-link">
                  Perfil
                </Link>
              </Nav>
              <Nav>
                <Link to="/my-account" className="nav-link">
                  Mi cuenta
                </Link>
              </Nav>
              <Nav>
                <Link to="/canjes" className="nav-link">
                  Canjes
                </Link>
              </Nav>
              <Nav>
                <Link to="/canjes/checkout" className="nav-link">
                    { cart.length === 0 && <i className="bi bi-cart"></i> }
                    { cart.length > 0 && <i className="bi bi-cart-fill"></i> }
                    ({cart.length})
                </Link>
              </Nav>
            </Nav>
          <Navbar.Toggle />
            <UserInfoBanner info={info} />
        </Container>
      </Navbar>
		<Content className={"mt-5 pt-5"}>
			<Container className="white my-4 border shadow">
				<Row>
					<Col xs={12} md={12}>
					  {children}
					</Col>
				</Row>
			</Container>
		</Content>
		<Footer />
	</>
	);
};

export default LayoutCanjes;
