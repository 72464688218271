import React from "react";

const Credential = ({
  rut,
  nombre_completo,
  numero_socio,
  commune,
  imagen,
}) => {
  return (
    <>
      <div id="credencial">
        <div
          id="content-credencial"
          style={{ display: "flex" }}
          className="justify-content-center align-items-center"
        >
          {imagen !== null ? (
            <img
              src={`${process.env.REACT_APP_URL_S3}${imagen}`}
              alt=""
              className="laimagen img-fluid"
              id="imagen_socio"
            />
          ) : (
            <img
              src={`https://cdn-icons-png.flaticon.com/512/149/149071.png`}
              alt=""
              className="laimagen img-fluid"
              id="imagen_socio"
              style={{ marginLeft: 27 + "px" }}
            />
          )}
          <div className="text-dark mb-0 lainfo">
            <div className="name">
              <p className="m-0">
                <strong className="text-uppercase">{nombre_completo}</strong>
              </p>
              <p className="m-0">
                <strong className="text-uppercase red">
                  SOCIO Nº: {numero_socio}
                </strong>
              </p>
            </div>
            <div className="rut">RUT: {rut}</div>
            <div className="comuna">COMUNA: {commune?.nombre}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Credential;
