
import { 
	List,
	FlexboxGrid,
	Button
} from 'rsuite';
import { addToCart, removeFromCart, removeQuantity } from '../../store/canjes'
import { useDispatch, useSelector } from 'react-redux';

const dataStyle = {
    fontSize: '1.2em',
    fontWeight: 500
};

const styleCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px'
};

const slimText = {
    fontSize: '0.666em',
    fontWeight: 'lighter',
    paddingBottom: 5,
    display: 'flex'
};

const titleStyle = {
    paddingBottom: 5,
    whiteSpace: 'nowrap',
    fontWeight: 500
};  

const CartItem = ({item, index}) => {

    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cartReducer.products);
    
    const add = (item) => {
        dispatch(addToCart(item))
    }

    const removeItem = (item) => {
        dispatch(removeQuantity(item));
    }

    const getCurrentQuantity = (item) => {
        const product = cart.find( p => p.id === item.id);

        if (!product) return;

        return product.quantity;
    }

    return (
        <>
            <List.Item key={item.id} index={index + 1}>
                <FlexboxGrid>
                {/*icon*/}
                <FlexboxGrid.Item colspan={2} style={styleCenter}>
                    <img src="https://admin.csdcolocolo.cl/imagenes/productos/CamisetaFutsalHombreArquero1.jpeg" style={{
                        maxWidth: '100%'
                    }}/>
                </FlexboxGrid.Item>
                {/*base info*/}
                <FlexboxGrid.Item
                    colspan={11}
                    style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden'
                    }}
                >
                <div>{item.title}</div>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2} style={styleCenter}>
                    <div style={{ textAlign: 'right' }}>
                    <div style={slimText}>{item.points} Puntos</div>
                    <div style={dataStyle}>{item.puntos}</div>
                    </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={1} style={styleCenter}>
                    <div style={{ textAlign: 'right' }}>
                    <div style={slimText}>X {item.quantity}</div>
                    </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={4} style={styleCenter}>
                    <div style={{ textAlign: 'right' }}>
                        <div style={slimText}>
                            <button onClick={ () => add(item)}>+</button>
                            <span className="text-center" style={{minWidth: '50px'}}>{ getCurrentQuantity(item) }</span>
                            <button onClick={ () => removeItem(item)}>-</button>
                        </div>
                    </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item
                    colspan={3}
                    style={{
                    ...styleCenter
                    }}
                >

                    <Button 
                        color="red" 
                        appearance="ghost"
                        onClick={ (e) => dispatch(removeFromCart(item.id))}
                    >
                        Quitar
                    </Button>
                </FlexboxGrid.Item>

                </FlexboxGrid>
            </List.Item>
        </>
    )
}

export default CartItem;