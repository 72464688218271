import React from "react";
import { Link } from "react-router-dom";
import LayoutFull from "../../Components/Layout/LayoutFull";

const NoValidateEmail = () => {
  return (
    <LayoutFull>
      <div className="mt-3">
        <h2>Existe validación</h2>
        <p>La validación de este correo se encuentra realizada</p>
        <div className="row mb-4">
          <div className="col-12 offset-md-5 col-md-2">
            <Link to={"/"} className="btn bg-red full">
              Volver
            </Link>
          </div>
        </div>
      </div>
    </LayoutFull>
  );
};

export default NoValidateEmail;
