import React from "react";
import { Col, Row } from "react-bootstrap";
import Credential from "../../Components/Credential/Credential";
import Layout from "../../Components/Layout/Layout";
import LocalStorage from "../../Service/LocalStorage";
import ProfilePictureChanger from "../../Components/Profile/ProfilePictureChanger";

const Credencial = () => {
  const info = LocalStorage?.getInformations().data;
  return (
    <Layout>
      <div className="ms-md-4 my-3">
        <h2 className="title">
          <strong>Mi Credencial Virtual</strong>
        </h2>
        <Row className="justify-content-end align-items-end">
          <Col md={8}>
            <Credential {...info} />
          </Col>
          <Col md={4}>
            <p>
              ¡Ser socio/a al día es un honor, y la manera más linda de ser Colocolino/a! 
              Te invitamos a adquirir la credencial física que te acredita como tal, 
              pinchando el siguiente enlace:
            </p>
            <a
              href="https://mi.csdcolocolo.cl/producto/credencial-2022-campen-en-la-quiebra-252"
              target={"_blank"}
              rel="noreferrer"
              className="btn bg-red"
            >
              Comprar
            </a>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Credencial;
