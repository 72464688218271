import React, { useRef, useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Logo from "../../Components/Logo/Logo";
import FormRegister from "./FormRegister";
import { Form, Message, Schema, toaster } from "rsuite";
import * as Api from "../../api";

const Register = () => {
  const history = useNavigate();
  const formRef = useRef();
  const model = Schema.Model({
    rut: Schema.Types.StringType()
      .isRequired("Este campo es obligatorio.")
      .pattern(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "Rut no válido"),
    nombres: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
    apellido: Schema.Types.StringType().isRequired(
      "Este campo es obligatorio."
    ),
    segundo_apellido: Schema.Types.StringType().isRequired(
      "Este campo es obligatorio."
    ),
    email: Schema.Types.StringType()
      .isRequired("Este campo es obligatorio.")
      .isEmail(
        "Por favor, introduce una dirección de correo electrónico válida."
      ),
    email_confirm: Schema.Types.StringType()
      .isRequired("Este campo es obligatorio.")
      .isEmail(
        "Por favor, introduce una dirección de correo electrónico válida."
      ),
    telefono: Schema.Types.NumberType()
      .min(100000000, "Teléfono debe tener 9 dígitos")
      .max(999999999, "Teléfono debe tener 9 dígitos"),
    celular: Schema.Types.NumberType()
      .isRequired("Este campo es obligatorio.")
      .min(100000000, "Celular debe tener 9 dígitos")
      .max(999999999, "Celular debe tener 9 dígitos"),
    genero: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
    pais: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
    fecha_nacimiento: Schema.Types.StringType().isRequired(
      "Este campo es obligatorio."
    ),
    region: Schema.Types.NumberType()
      .isRequired("Este campo es obligatorio.")
      .min(1, "Debe seleccionar una región"),
    comuna: Schema.Types.NumberType()
      .isRequired("Este campo es obligatorio.")
      .min(1, "Debe seleccionar una comuna"),
    direccion: Schema.Types.StringType().isRequired(
      "Este campo es obligatorio."
    ),
    direccion_nro: Schema.Types.StringType().isRequired(
      "Este campo es obligatorio."
    ),
    password: Schema.Types.StringType().isRequired(
      "Este campo es obligatorio."
    ),
    password_confirmation: Schema.Types.StringType().isRequired(
      "Este campo es obligatorio."
    ),
  });

  const [registerStatus, setRegisterStatus] = useState(false);

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Message type="error">
          Existen campos que no cumplen con las validaciones
        </Message>
      , {placement: 'topCenter'});
      return;
    }
    if (model.data.email !== model.data.email_confirm) {
      toaster.push(<Message type="error">Los email no coinciden</Message>);
      return;
    }
    if (model.data.password !== model.data.password_confirmation) {
      toaster.push(<Message type="error">Las password no coinciden</Message>);
      return;
    }   
    const tokenInvitation = getTokenInvitation();

    const dataToSend = {...model.data, token_invitation: tokenInvitation};

    const data = await Api.Register(dataToSend);

    if (data.status) {
      toaster.push(
        <Message type="success">
          Se registró correctamente, fue enviado un email para validar su
          correo.
        </Message>
      );
      setRegisterStatus(true);
      return;
    } else {
      if (data.validations !== undefined) {
        let validations = Object.keys(data.validations);
        let errores = Object.keys(data.validations[validations[1]]);

        console.log("errores", errores, "validations: ", data.validations.errors);

        toaster.push(
          <Message type="error">
          {
            errores.map ( item => (
              <span>{data.validations.errors[item]}<br /></span> 
            ))
          }
          </Message>
        );
      }
    }
  };

  const getTokenInvitation = () => {
    var url = new URL(window.location);
    const token = url.searchParams.get('token_invitation');

    if (!token) return '';

    return token;
  }

  return (
    <>
      <Form model={model} ref={formRef}>
        <div className="container-fluid log">
          <div className="row black">
            <div className="col-12 col-md-8 text-end white">
              <div className="login py-4 full">
                <Logo className="mb-4" />
                {
                  registerStatus ? 
                  <>
                    <Message showIcon type="success" header="Registrado exitosamente.">
                        Se registró correctamente, un email fue enviado a su casilla para confirmar el registro.
                    </Message>
                  </> : 
                  <>
                    <h2>Hazte Socio</h2>
                    <p className="m-0">
                      Para ser socio/a e integrarte a la gran familia del Club Social y Deportivo Colo-Colo, por favor completa el siguiente formulario de inscripción
                    </p>
                    <p>Completa el formulario de Inscripción</p>
                    <FormRegister {...model} />
                    <button className="btn bg-red full mt-4" onClick={handleSubmit}>
                      Registrar
                    </button>
                    <div className="recovery mt-4">
                      <Link to="/" className="link tx-dark">
                        Volver
                      </Link>
                    </div>
                  </>
                }
              </div>
            </div>
            <div className="col-12 col-md-4 my-3">
              <img
                src={"https://mi.csdcolocolo.cl/img/tabla_socios.jpg"}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </Form>
      <Footer />
    </>
  );
};

export default Register;
