import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Api from "../../api";
import LayoutFull from "../../Components/Layout/LayoutFull";
import LocalStorage from "../../Service/LocalStorage";

const ValidateTwo = () => {
  const [info, setInfo] = useState([]);
  const [option, setOption] = useState([]);
  const history = useNavigate();

  const getInteres = async (e) => {
    const response = await Api.getIntereses();
    setInfo(response);
  };
  const handleSetInteres = async (e) => {
    const response = await Api.setIntereses({
      interests: option,
    });
    history("/validation/pago/incorporacion");
  };
  const handleOptionSelected = async (e) => {
    if (e.target.checked) {
      setOption([...option, e.target.value]);
    } else {
      var index = option.indexOf(e.target.value);
      while (index > -1) {
        option.splice(index, 1);
        index = option.indexOf(e.target.value);
      }
    }
  };
  useEffect(() => {
    getInteres();
  }, []);
  return (
    <LayoutFull>
      <div className="mt-3">
        <h2>Tus Gustos e intereses</h2>
        <p>Cuéntanos acerca de tus gustos e intereses</p>
        <div className="row my-3">
          {info.map((item, index) => (
            <div className="col-6 col-md-3" key={index}>
              <input
                type={"checkbox"}
                value={item.name}
                onClick={(e) => {
                  handleOptionSelected(e);
                }}
              />{" "}
              <span>{item.name}</span>
            </div>
          ))}
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <Link
              to={"/validation/pago/incorporacion"}
              className="btn bg-grey full"
            >
              Omitir Paso
            </Link>
          </div>
          <div className="col-6">
            <button
              className="btn bg-black full"
              onClick={(e) => {
                handleSetInteres(e);
              }}
            >
              Guardar Intereses
            </button>
          </div>
        </div>
      </div>
    </LayoutFull>
  );
};

export default ValidateTwo;
