import React, { useEffect, useState } from "react";
import LayoutFull from "../../Components/Layout/LayoutFull";
import * as Api from "../../api";
import { Container } from "react-bootstrap";
import { formatNumeric } from "../../Service/Utils";
import { Link } from "react-router-dom";
import LocalStorage from "../../Service/LocalStorage";
import { Message, Panel } from 'rsuite';

const PaymentVoucher = () => {
  const [info, setInfo] = useState([]);
  const [secondaryPayments, setSecondaryPayments] = useState([]);
  const socio = LocalStorage?.getInformations().data;
  const [loading, setLoading] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const id_compra = new URLSearchParams(window.location.search).get(
    "id_compra"
  );
  const getPayment = async () => {
  	setLoadingPayment(true);
    const response = await Api.getPaymentCuota(id_compra);
    if (response.data.main_payment?.estado === 2) {
    	setLoading(false);
    } else {
    	setTimeout(function() {
			getPayment();
		}, 5000);

    	setLoading(true);
    }

    setInfo(response.data.main_payment);
    setSecondaryPayments(response.data.secondary_payments);
    setLoadingPayment(false);
  };

  const updateInfoSocio = async () => {
  		const response = await Api.getInfoSocio();
		LocalStorage.setInformations(response.data);
  }

  useEffect(() => {

  	updateInfoSocio();

	setLoadingPayment(true);
	setTimeout(function() {
		getPayment();
	}, 5000);

  }, []);	
  

  const getTotalSecondaryPayments = () => {
    const total = secondaryPayments
          .map (item => item.total)
          .reduce( (accum, current) =>  accum + current, 0);

    return formatNumeric(total);
  }
  return (
    <LayoutFull>
      <Container className="my-4">

        { !loadingPayment && loading && info.estado != 2 && (
			<>
      			<div className="alert alert-info text-center" role="alert">
					<h4>Tu pago con código {info.id} está en proceso de confirmación.</h4> <br />
					<p>
						Te notificaremos por correo con la confirmación del pago.
					</p>

					<p>
						Además puedes revisar el estado de tu pago en tu
						<Link to={"/account/payments/history?menu=payments-history"} className="m-1">
		                  	Historial de pagos
		                </Link>
					</p>

				</div>
      		</>
        )}


        {
        	loadingPayment && (

        	<div className="alert alert-info text-center">

        		<h4>Cargando información de pago...</h4>
        		<div className="spinner-border text-dark" role="status">
				  <span className="sr-only"></span>
				</div>
        	</div>
		)}


        {
        	!loadingPayment && info.estado === 2 && (
        		<>
		            <div>
		            	<div className="alert alert-success">
		            		<h2>
			                	Felicitaciones, tu pago se completó de forma exitosa
			              	</h2>
		            	</div>
		              <p className="red">
		                SOCIO N° {socio?.numero_socio}
		              </p>
		              <p>Código del pago: {info.id}</p>
		            </div>
		            <div>
		              <h2>Datos del pago</h2>
		              <ul>
		                <li>Nombre socio: {socio?.nombre_completo}</li>
		                <li>N° de pedido: {info.id}</li>
		                <li>Comercio: CSD Colo Colo</li>
		                <li>Monto:${formatNumeric(info.total)}</li>
		                <li>Código de autorización: {info.wp_authorizationCode}</li>
		                <li>Fecha trannsacción:{info.fecha}</li>
		                <li>Tipo de pago: Redcompra</li>
		                <li>Tipo de cuota: {info.wp_paymentTypeCode}</li>
		                <li>Cantidad de cuotas: {info.numero_cuota}</li>
		                <li>4 últimos digitos tarjeta: {info.wp_cardNumber}</li>
		              </ul>
		            </div>
		            <div>
		              <h2>Pago</h2>
		              <table className="table">
		                <tr>
		                  <td colSpan={4}></td>
		                  <td>
		                    <p className="semi-bold m-0 p-0 red">#{info.id}</p>
		                  </td>
		                </tr>
		                <tr>
		                  <th>Fecha</th>
		                  <th>Socio</th>
		                  <th>Cuota</th>
		                  <th>Cantidad</th>
		                  <th>Monto</th>
		                </tr>
		                <tr>
		                  <td>{info.fecha}</td>
		                  <td>
		                    <p className="m-0 p-0">{socio?.nombre_completo}</p>
		                    <p className="m-0 p-0">{info.rut}</p>
		                  </td>
		                  <td>${formatNumeric(info.cuota)}</td>
		                  <td>{info.numero_cuota}</td>
		                  <td>${formatNumeric(info.subtotal)}</td>
		                </tr>
		                <tr>
		                  <td colSpan={3}></td>
		                  <td>
		                    <p className="semi-bold m-0 p-0">Total pago socio</p>
		                  </td>
		                  <td>${formatNumeric(info.total)}</td>
		                </tr>
		              </table>
		              <hr />

		              {
		                secondaryPayments.length > 0 ?
		                <>
		                  <h4>Pagos cuentas asociadas</h4>
		                  <table className="table">
		                    <tr>
		                      <th>Fecha</th>
		                      <th>Socio</th>
		                      <th>Cuota</th>
		                      <th>Cantidad</th>
		                      <th>Monto</th>
		                    </tr>

		                    {
		                        secondaryPayments.map ( item => (
		                            <tr>
		                              <td>{item.fecha}</td>
		                              <td>
		                                <p className="m-0 p-0">{item.user?.nombre_completo}</p>
		                                <p className="m-0 p-0">{item.user?.rut}</p>
		                              </td>
		                              <td>${formatNumeric(item.cuota)}</td>
		                              <td>{item.numero_cuota}</td>
		                              <td>${formatNumeric(item.total)}</td>
		                            </tr>
		                        ))
		                    }                  
		                    <tr>
		                      <td colSpan={3}></td>
		                      <td>
		                        <p className="semi-bold m-0 p-0">Total cuentas asociadas</p>
		                      </td>
		                      <td>${getTotalSecondaryPayments()}</td>
		                    </tr>
		                  </table>
		                  <hr />
		                </> : <>

		                </>
		              }

		              <div className="row">
		                  <div className="col-md-12 d-flex justify-content-end">
		                      <span className="semi-bold">Total: </span>
		                      <span> ${formatNumeric(info.wp_amount)} </span>
		                  </div>
		              </div>
		              <div>
		                <Link to={"/dashboard"} className="btn">
		                  Volver
		                </Link>
		              </div>
		            </div>
		          </>
		)}

      </Container>
    </LayoutFull>
  );
};

export default PaymentVoucher;
