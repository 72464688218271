import hookup from "./hookup";


export const PayCuotaNormal = async (data) => {
  return await hookup("post", "payments/create-payment-cuotas", data);
};
export const PayCuotaExtra = async (data) => {
  return await hookup("post", "payments/create-payment-cuotas-extra", data);
};
export const PayRegularizacion = async (data) => {
  return await hookup("post", "payments/create-payment-cuotas-regularizacion", data);
};
export const SubscribeOneClick = async (data) => {
  return await hookup("post", "payments/transbank/oneclick/subscribe", data);
};
export const PayCuotaNormalOneClick = async (data) => {
  return await hookup("post", "payments/transbank/oneclick/authorize/cuota", data);
};
export const getOneclickSubscription = async () => {
  return await hookup("get", "payments/transbank/oneclick/subscription");
};
export const deleteCard = async () => {
  return await hookup("post", "payments/transbank/oneclick/unsubscribe");
};
export const getCommunes = async (id) => {
  return await hookup("get", `communes/${id}`, {});
};
export const getTotalSocio = async () => {
  return await hookup("get", "socios/total", {});
};

export const getInfoPartidos = async (data) => {
  return await hookup("get", "fixtures", data);
};
//socios
export const SingIn = async (data) => {
  return await hookup("post", "socios/login", data);
};
export const Register = async (data) => {
  return await hookup("post", "socios/store", data);
};
export const getInfoSocio = async (data) => {
  return await hookup("get", "socios/profile", data);
};
export const getProductsCanjes = async (data) => {
  return await hookup("get", "canjes/products", data);
};
export const getCategoryCanjes = async () => {
  return await hookup("get", "canjes/category", {});
};
export const getProfileData = async (data) => {
  return await hookup("get", "socios/profile/data", data);
};
export const getCuotasPendientes = async (data) => {
  return await hookup("get", "socios/profile/cuotas/pendientes", data);
};
export const getIntereses = async (data) => {
  return await hookup("get", "interests", data);
};
export const setIntereses = async (data) => {
  return await hookup("post", "socios/profile/set-interests", data);
};
export const getPaymentCuota = async (id) => {
  return await hookup("get", `payments/info/${id}`, {});
};

export const getPaymentHistory = async () => {
  return await hookup("get", `socios/account/payments/history`);
};

export const getPurchasesHistory = async () => {
  return await hookup("get", `socios/account/purchases/history`);
};

export const getRegularizationPendingAmount = async () => {
  return await hookup("get", `socios/account/payments/regularization/pending-amount`);
};


// Asociar cuentas

export const sendInvite = async (data) => {
    return await hookup("post", "socios/account/invite_associated", data);
}
export const sendInviteNewPartner = async (data) => {
    return await hookup("post", "socios/account/invite_register", data);
}
export const getAssociatedAccounts = async () => {
    return await hookup("get", "socios/account/associated", {});
}
export const deleteAssociatedAccount = async (id) => {
    return await hookup("post", `socios/account/delete_associated`, {user_id: id});
}

// Profile

export const updateProfile = async (data) => {
	return await hookup("post", "socios/account/profile/update", data);
}

// Account
export const updatePassword = async (data) => {
    return await hookup("post", "socios/account/update-password", data);
}

// Password recovery
export const sendRecoveryLink = async (data) => {
	return await hookup("post", "socios/account/password/send-recover-link", data);
}

export const resetPassword = async (data) => {
	return await hookup("post", "socios/account/password/reset-password", data);
}

// Pagos automaticos

export const autorizeAutomaticPayments = async (data) => {
	return await hookup("post", "socios/account/payments/config/authorize-auto-payment", data);
}

export const getConfig = async () => {
	return await hookup("get", "socios/account/payments/config");
}


// Puntos

export const getPoints = async () => {
    return await hookup("get", "socios/account/points");
}

export const CanjeCheckout = async (data) => {
    return await hookup("post", "canjes/checkout", data);
}

export const GetCanjeData = async (id) => {
    return await hookup("get", `canjes/get_data/${id}`, {});
}
/*export const setImageSocio = async (data) => {
  return await hookup("post", "socios/profile/upload-picture/123", data);
};*/