import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Content } from "rsuite";
import Footer from "../Footer/Footer";
import HeaderComponent from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import * as Api from "../../api";
import LocalStorage from "./../../Service/LocalStorage";

const Layout = ({ children }) => {
  const history = useNavigate();
  const getInfo = async () => {
    if (LocalStorage.getToken().token !== null) {
      if (parseInt(LocalStorage.getStatus().status) === 1) {
        const response = await Api.getInfoSocio();
        LocalStorage.setInformations(response.data);
        LocalStorage.setStatus(0);
      }
    } else {
      history("/");
    }
  };
  getInfo();
  return (
    <>
      <HeaderComponent show={true} />
      <Content className={"mt-5 pt-5"}>
        <Container className="white my-4 border shadow">
          <Row>
            <Col xs={12} md={4} className="border-end">
              <Sidebar />
            </Col>
            <Col xs={12} md={8}>
              {children}
            </Col>
          </Row>
        </Container>
      </Content>
      <Footer />
    </>
  );
};

export default Layout;
