import React, { useState } from "react";
import {Input, InputGroup, List, Message} from "rsuite";
import * as Api from "../../api";
import { Card, Spinner } from "react-bootstrap";
import ListAssociatedAccounts from "./ListAssociatedAccounts";

const AfiliatedAccounts = ({}) => {

    const [form, setForm] = useState({
        rut: ''
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const [genericError, setGenericError] = useState();
    const [invitationSent, setInvitationSent] = useState(false);

    const handleInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleInvite = async () => {
        setLoading(true);
        setInvitationSent(false);

        const data = await Api.sendInvite(form);

        if (data.validations) {
            setErrors(data.validations.message);
            setGenericError('');
            setLoading(false);
            return;
        }

        if (!data.status) {
            setGenericError(data.data);
            setErrors('');
            setLoading(false);
            return;
        }

        if (data.status) {
            setInvitationSent(true);
            setErrors('')
            setGenericError('');
            setForm({ rut: ''})
        }

        setLoading(false);
    }

	return (
		<>
			<div className="row m-2">
				<div className="col-12 col-md-8">
					<h4><strong><i className="fas fa-user"></i>Asociar cuentas</strong></h4>

					<p>
                        Ahora puedes realizar los pagos de los integrantes de tu grupo familiar sin necesidad de entrar cada vez a la cuenta de cada uno/a. 
                        <br /><br />
                        Para esto, indica a continuación el RUT del socio/a que deseas afiliar bajo tu tutela (puedes agregar todos los que quieras, y tendrás la posibilidad de eliminarlos cuando desees. El registrar sus cuentas bajo tu tutela no te obliga a pagar sus cuotas):
                    </p>
                    <div className="d-flex">
                        <label htmlFor="rut" className="visually-hidden">RUT</label>
                        <input type="text" name="rut" className="form-control" id="rut" placeholder="123456789" onChange={handleInput} value={form.rut}/>
                        <button className="d-flex justify-content-between align-items-center btn btn-primary" onClick={handleInvite} disabled={loading}>
                            Enviar invitación 
                            { loading ? <Spinner animation="grow" size="sm" /> : <></>}
                        </button>
                    </div>
                    {
                        genericError ? 
                        <>
                            <span className="text-danger">{ genericError }</span>
                            <br />
                        </> : <></>
                    }
                    { errors && <span className="text-danger">{ errors }</span> }
                    {
                        invitationSent ? 
                        <>
                            <Message showIcon type="success" header="Invitación enviada" closable>
                                Se ha enviado un correo con la invitación.
                            </Message>
                        </> : <></>
                    }
				</div>
			</div>			

			<div className="row m-2">
                <ListAssociatedAccounts />
                <br />
            </div>
		</>
	)
}

export default AfiliatedAccounts;