import React, { useRef, useState, useEffect } from "react";
import * as Api from "../../api";
import { Message, Panel, toaster } from 'rsuite';
import { Spinner } from "react-bootstrap";


const CreditCard = ({onDelete}) => {

	const [deletingCard, setDeletingCard] = useState(false);
	const [subscription, setSubscription] = useState({});
	const [urlOneclick, setUrlOneclick] = useState('');
	const [token, setToken] = useState('');
	const webpayForm = useRef();
	const getOneclickSubscription = async () => {
		const res = await Api.getOneclickSubscription();

		if (res.data && res.status && res.data.user) {
			setSubscription(res.data);
		}
	}

	console.log(subscription)
	const deleteCard = async (e) => {
		if (window.confirm('Confirmar eliminacion de tarjeta')) {
		  setDeletingCard(true);
		  const res = await Api.deleteCard();
		  if (res && res.status) {
		    toaster.push(
		      <Message type="success">
		        Tarjeta eliminada exitosamente
		      </Message>
		    , {placement: 'topCenter'});
            if (onDelete) onDelete();
		    setDeletingCard(false);
		  }
		}
	}


	const addCard = async (e) => {

		const formData = {
			tipo_pago: 'pago_automatico',
		}
		
		const res = await Api.SubscribeOneClick(formData);

		setUrlOneclick(res.url);
		setToken(res.ws_token);
		webpayForm.current.submit();
	}


	const Header = () => {
		return (
			<>
				<i className="bi bi-credit-card"></i> { subscription.card_type }
			</>
		)
	}

	useEffect( () => {
		console.log(subscription)
		getOneclickSubscription();
	}, []);

	return (
		<Panel bordered header={ subscription?.user && <Header /> }>

			<form name="form_webpay" action={urlOneclick} method="POST" id="form_webpay" ref={webpayForm}>
				<input type="hidden" name="TBK_TOKEN" value={token} />
			</form>

			{
				subscription?.user ? <>
					{ subscription.card_number } <br />

					{
						deletingCard ? 
						<>
						  	<Spinner animation="grow" className="mt-3" />
						  	<p className="semi-bold">Eliminando tarjeta...</p>
						</> : 
						<>
						  	<button className="btn btn-danger btn-xs" onClick={ deleteCard }>Eliminar tarjeta</button>
						</>
			      	}
				</> : <>
					<button className="btn btn-success btn-xs" onClick={ addCard }>
						Agregar tarjeta <i className="bi bi-credit-card"></i>
					</button>
				</>
			}
	    </Panel>
	)
        
}

export default CreditCard;