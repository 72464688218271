import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import { formatDate, formatNumeric } from "../../Service/Utils";
import * as Api from "../../api";
import LocalStorage from "../../Service/LocalStorage";
import CardCuota from "../../Components/Payment/CardCuota";

const Home = () => {
  const [info, setInfo] = useState([]);
  const [total, setTotal] = useState(0);
  const [n_cuotas, setNumero] = useState(0);
  const [active, setActive] = useState(false);
  const [canRegularize, setCanRegularize] = useState(false);

  const getTotal = async (e) => {
    const response = await Api.getTotalSocio();
    setTotal(response.total);
    setInfo(LocalStorage.getInformations().data);
  };
  const getNumeroCuotas = async (e) => {
    const response = await Api.getCuotasPendientes({
      rut: info?.rut,
    });
    setNumero(response.cuotas_pendientes);
    setCanRegularize(response.puede_regularizar);
    setActive(true);
  };
  useEffect(() => {
    getTotal();
    getNumeroCuotas();
  }, []);
  return (
    <>
      <div className="mt-3">
        <h3>
          Ya somos <span className="semi-bold">{formatNumeric(total)}</span>{" "}
          Socios al día
        </h3>
        {info?.al_dia_hasta === null ? (
          <p className="red mb-3">
            Su cuota de inscripción no se encuentra pagada
          </p>
        ) : (
          <p>
            Te encuentras inscrito desde{" "}
            <span className="semi-bold">{formatDate(info?.socio_desde)}</span> y
            tienes tus cuotas al día hasta{" "}
            <span className="semi-bold">{formatDate(info?.al_dia_hasta)}</span>
          </p>
        )}
      </div>
      <div className="row">
        {info?.al_dia_hasta === null ? (
          <div className="col-12 col-md-4">
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Cuota Incorporación</Card.Title>
                <Card.Text>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </Card.Text>
                <Card.Footer className="text-center">
                  {active ? (
                    <Link
                      to={`/pago/cuota-inscripcion`}
                      state={{ type_pay: "incorporacion" }}
                      className="btn bg-red"
                    >
                      PAGAR
                    </Link>
                  ) : (
                    <Spinner animation="grow" className="mt-3" />
                  )}
                </Card.Footer>
              </Card.Body>
            </Card>
          </div>
        ) : (
        	<>
        		<CardCuota n_cuotas={n_cuotas} title={"Cuota Normal"} type='normal' route={'/pago/cuota?type_cuota=normal'} description={`
        			Gracias a tu apoyo el club puede desarrollar todos sus proyectos deportivos y sociales. 
							No olvides mantenerte al día para también poder acceder a nuestros beneficios.
        		`} />
        		<CardCuota n_cuotas={n_cuotas} title={"Cuota Extraordinaria"} type='extraordinaria' route={'/pago/cuota?type_cuota=extraordinaria'} description={`
        			Si deseas aportar un monto adicional para apoyar al club en el desarrollo de sus actividades, puedes hacerlo a través del pago de cuotas extraordinarias. Ingresa acá para más información.
        		`} btnText={'APORTAR'}/>

        		{
        			canRegularize && n_cuotas >= 24 && <CardCuota n_cuotas={n_cuotas} title={"Regularización"} type='regularizacion' route={'/pago/regularizacion?type_cuota=regularizacion'} description={`
        			Si debes 24 o más cuotas, puedes regularizar tu situación pagando el 50% de la deuda. Ingresa acá para más información.
        		`} btnText={'REGULARIZARME'}/>
        		}
        	</>
        )}
      </div>
    </>
  );
};

export default Home;
