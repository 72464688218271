import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './canjes';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const persisConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persisConfig, counterReducer);

export const store = configureStore({
    reducer: {
        cartReducer: persistedReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
});

export const persistor = persistStore(store);