import React from "react";
import { Col, Form, Grid, Row } from "rsuite";
import Selecters from "./Selecters";

const FormRegister = (props) => {
  const TextField = ({ name, label, type, accepter, ...rest }) => (
    <>
      <Form.Group controlId={name} className="mb-3">
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control name={name} accepter={accepter} {...rest} type={type} />
      </Form.Group>
    </>
  );
  return (
    <Grid fluid>
      <Row>
        <Col xs={24} sm={12} md={12}>
          <TextField name="rut" label="Rut* (sin puntos y sin guión)" type="text" />
        </Col>
        <Col xs={24} sm={12} md={12}>
          <TextField name="nombres" label="Nombres*" type="text" />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12} md={12}>
          <TextField name="apellido" label="Apellido Paterno*" type="text" />
        </Col>
        <Col xs={24} sm={12} md={12}>
          <TextField
            name="segundo_apellido"
            label="Apellido Materno*"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12} md={12}>
          <TextField name="email" label="E-mail*" type="email" />
        </Col>
        <Col xs={24} sm={12} md={12}>
          <TextField name="email_confirm" label="Repetir E-mail*" type="email" />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12} md={12}>
          <TextField name="telefono" label="Teléfono" type="text" />
        </Col>
        <Col xs={24} sm={12} md={12}>
          <TextField name="celular" label="Celular*" type="text" />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12} md={12}>
          <TextField name="pais" label="Pais*" type="text" />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <TextField
            name="fecha_nacimiento"
            label="Fecha de Nacimiento*"
            type="date"
          />
        </Col>
      </Row>
      <Row>
        <Selecters />
      </Row>
      <Row>
        <Col xs={24} sm={8} md={8}>
          <TextField name="direccion" label="Calle*" type="text" />
        </Col>
        <Col xs={24} sm={8} md={8}>
          <TextField name="direccion_nro" label="Numeración*" type="text" />
        </Col>
        <Col xs={24} sm={8} md={8}>
          <TextField
            name="direccion_dpto"
            label="Dpto/Block/Casa"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <h4 className="my-3">CLAVE DE ACCESO</h4>
        </Col>
        <Col xs={24} sm={12} md={12}>
          <TextField name="password" label="Password*" type="password" />
        </Col>
        <Col xs={24} sm={12} md={12}>
          <TextField
            name="password_confirmation"
            label="Confirmar Password *"
            type="password"
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default FormRegister;
