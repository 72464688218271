import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const ProfilePictureForm = ({
	profilePictureUrl, 
	handleFile,
	hasError,
	errors,
	successMessage,
	handleUpload,
	loading,
}) => {
	return (
		<>
			<Card className="mb-4">
				<Card.Body>
					<Card.Title>Tu imagen de Credencial:</Card.Title>
					<Card.Text>
					<img
				      src={profilePictureUrl}
				      alt=""
				      className="m-2 img-default"
				    />
				    <input type="file" onChange={(e) => handleFile(e)} />
					</Card.Text>
					<Card.Footer className="text-start">
						<p className="m-0">
							-. Agrega tu fotografía de rostro frontal. Se recomienda que la
							imagen tenga un tamaño de 400x400 pixeles, estar en formato PNG,
							JPG o GIF no animado y no pesar más de 2Mb.
						</p>
						<p className="m-0">
							-. Podrás adquirir tu credencial física solicitándola
							posteriormente.
						</p>
					</Card.Footer>
				</Card.Body>
			</Card>

							{
			hasError && errors && (
					<div className="d-flex justify-content-start align-items-start flex-column">
						<div className="alert alert-danger">
							<span>{errors.profile_picture}</span>
						</div>
					</div>
				)	
			}
			{
				successMessage && (
        			<div className="alert alert-success">
                		{ successMessage }
                	</div>
        		)
			}
			<div className="row mb-4">
				<div className="col-md-6">
					<button
					  className="btn btn-primary"
					  disabled={loading}
					  onClick={(e) => {
					    handleUpload(e);
					  }}
					>
					  Guardar Foto { loading && <Spinner animation="grow" size="sm" /> }
					</button>
				</div>
				<div className="col-md-6">
					<Link to="/credencial" className="btn btn-primary">
	                  	Ver Credencial
	                </Link>
				</div>
			</div>
		</>
	);
};

export default ProfilePictureForm;
