import React, { useRef, useState, useEffect, forwardRef } from "react";
import Layout from "../../Components/Layout/Layout";
import LocalStorage from "../../Service/LocalStorage";
import { formatRut } from "../../Service/Utils";
import { Spinner } from "react-bootstrap";
import * as Api from "../../api";
import ProfilePictureChanger from "../../Components/Profile/ProfilePictureChanger";
import Selecters from "../Register/Selecters";
import { 
	Form, 
	Message, 
	Schema, 
	toaster, 
	Whisper, 
	Tooltip, 
	SelectPicker,
	Row,
	Col
} from "rsuite";

import { MixedType } from 'schema-typed';

import { Link, useNavigate} from "react-router-dom";
import PasswordUpdater from "../../Components/Account/PasswordUpdater";
import TextField from "../../Components/Forms/TextField";

const MyProfile = () => {
    const info = LocalStorage?.getInformations().data;

    const [loadingProfileInfo, setLoadingProfileInfo] = useState(false);
    const [loaderUpdateProfile, setLoaderUpdateProfile] = useState(false);

    const [profileData, setProfileData] = useState({
    	rut: '',
    	nombre: '',
    	apellido: '',
    	apellidoM: '',
    	celular: '',
    	telefono: '',
    	email: '',
    	genero: '',
    	fecha_nacimiento: '',
    	tipo_socio: '',
    	pais: '',
    	direccion: '',
    	numero: '',
    	dpto: '',
    	region: '',
    	comuna: ''
    });

	const [options_comuna, setoptions_comuna] = useState([]);
	const [region, setRegion] = useState(0);
	const [comuna, setComuna] = useState(0);
	const [genero, setGenero] = useState('');

	const options_region = [

		{ value: 1, name: "I Región de Tarapacá" },
		{ value: 2, name: "II Región de Antofagasta" },
		{ value: 3, name: "III Región de Atacama" },
		{ value: 4, name: "IV Región de Coquimbo" },
		{ value: 5, name: "V Región de Valparaíso" },
		{ value: 6, name: "VI Región del Libertador General Bernardo O'Higgins" },
		{ value: 7, name: "VII Región del Maule" },
		{ value: 8, name: "VIII Región del Biobío" },
		{ value: 9, name: "IX Región de la Araucanía" },
		{ value: 10, name: "X Región de Los Lagos" },
		{ value: 11, name: "XI Región de Aysén" },
		{ value: 12, name: "XII Región de Magallanes y Antártica" },
		{ value: 13, name: "Región Metropolitana" },
		{ value: 14, name: "XIV Región de Los Ríos" },
		{ value: 15, name: "XV Región de Arica y Parinacota" },
		{ value: 16, name: "XVI Región de Ñuble" },

	];

	const options_genero = [
		{ value: "m", name: "Masculino" },
		{ value: "f", name: "Femenino" },
	];

	console.log(Schema)

    const history = useNavigate();
    const formRef = useRef();
    const model = Schema.Model({
		nombre: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
		apellido: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
		apellidoM: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
		email: Schema.Types
		.StringType()
		.isRequired("Este campo es obligatorio.")
		.isEmail("Por favor, introduce una dirección de correo electrónico válida."),
		telefono: Schema.Types
		.NumberType()
		.min(100000000, "Teléfono debe tener 9 dígitos")
		.max(999999999, "Teléfono debe tener 9 dígitos"),
		celular: Schema.Types.NumberType()
		  .isRequired("Este campo es obligatorio.")
		  .min(100000000, "Celular debe tener 9 dígitos")
		  .max(999999999, "Celular debe tener 9 dígitos"),
		genero: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
		// pais: Schema.Types.StringType().isRequired("Este campo es obligatorio."),
		fecha_nacimiento: Schema.Types
		.StringType()
		.isRequired("Este campo es obligatorio."),
		region: Schema.Types.NumberType()
		  .isRequired("Este campo es obligatorio.")
		  .min(1, "Debe seleccionar una región"),
		comuna: Schema.Types.NumberType()
		  .isRequired("Este campo es obligatorio.")
		  .min(1, "Debe seleccionar una comuna"),
		direccion: Schema.Types
		.StringType()
		.isRequired("Este campo es obligatorio."),
		numero: Schema.Types
		.NumberType('Debes escribir un numero')
		.isRequired("Este campo es obligatorio."),
		dpto: MixedType()
	});

	const handleSubmit = async () => {

		setLoaderUpdateProfile(true);


		if (!formRef.current.check()) {
			toaster.push(
				<Message type="error">
					Existen campos que no cumplen con las validaciones
				</Message>
			, {
				placement: 'topCenter'
			});

					console.log(model.data)


			setLoaderUpdateProfile(false);
		  return;
		}

		setLoadingProfileInfo(true);

		const userData = {...model.data, genero: genero, comuna: comuna, region, region };
		const data = await Api.updateProfile(userData);
		setLoadingProfileInfo(false);
		if (data.status) {
		  toaster.push(
		    <Message type="success">
		      Perfil actualizado exitosamente.
		    </Message>
		  );


			const response = await Api.getInfoSocio();
	  		LocalStorage.setInformations(response.data);
	  		
			setLoaderUpdateProfile(false);
			return;
		} else {
			if (data.validations !== undefined) {
				let validations = Object.keys(data.validations);
				let errores = Object.keys(data.validations[validations[1]]);

				console.log("errores", errores, "validations: ", data.validations.errors);

				toaster.push(
					<Message type="error">
					{
					errores.map ( item => (
					  <span>{data.validations.errors[item]}<br /></span> 
					))
					}
					</Message>
				);
			}

		  	setLoaderUpdateProfile(false);
		}
	};


	const getProfileInfo = async () => {
		const res = await Api.getProfileData();

		let newData = res.data;
		newData['genero'] = newData.genero === 'Hombre' ? 'm' : 'f';
		newData['region'] = newData.region_id;
		newData['comuna'] = newData.comuna_id;

		setGenero(newData['genero']);

	    const response = await Api.getCommunes(newData.region_id);
	    setoptions_comuna(response);
	    setRegion(newData.region_id);
	    setComuna(newData.comuna_id);

		console.log(res);

		setProfileData(newData);
		setLoadingProfileInfo(false);
	}

	useEffect( () => {
		setLoadingProfileInfo(true);
		getProfileInfo();
	}, []);

	const handleInput = (value, e) => {
		setProfileData({
            ...profileData,
            [e.target.name]: e.target.value,
        });
	}

	const regionSelector = forwardRef((props, ref) => (
		<SelectPicker
		  cleanable={false}
		  data={options_region}
		  valueKey="value"
		  labelKey="name"
		  {...props}
		  ref={ref}
		/>
	));
	const comunaSelector = forwardRef((props, ref) => (
		<SelectPicker
		  defaultValue={comuna}
		  cleanable={true}
		  data={options_comuna}
		  valueKey="id"
		  labelKey="nombre"
		  {...props}
		  ref={ref}
		/>
	));
	const generoSelector = forwardRef((props, ref) => (
		<SelectPicker
		  cleanable={false}
		  data={options_genero}
		  valueKey="value"
		  labelKey="name"      
		  {...props}
		  ref={ref}
		/>
	));
	
	const updateGenero = (e) => {
		console.log(e);
	}

	const getComumunes = async (id) => {
		setComuna(0);
		const response = await Api.getCommunes(id);
		setoptions_comuna(response);
		setRegion(id);
	};

    return (
        <Layout>

        	<Form model={model} ref={formRef} formValue={profileData} onChange={handleInput}>
				<div className="ms-md-4 my-3">
					<div className="col-md-12">
						<ProfilePictureChanger />
					</div>
					<div className="row info">
			        	{
			        		loadingProfileInfo ? 
			        		<>
				        		<div className="col-12 d-flex justify-content-center">
				        			<div className="spinner-border m-5" role="status">
									  <span className="sr-only"></span>
									</div>
				        		</div>
			        		</> : <>

								<h2 className="title">
								  <strong>Mis Datos</strong>
								</h2>

								<div className="col-md-12">
									<label>Rut</label>
									<p>
									<input
										value={formatRut(profileData.rut)}
										className="form-control"
										disabled
									/>
									</p>
								</div>
								<div className="col-4">
									<TextField name="nombre" label="Nombres" type="text" value={profileData.nombre} onChange={handleInput} />
								</div>

								<div className="col-4">
									<TextField name="apellido" label="Apellido Paterno" type="text" value={profileData.apellido} onChange={handleInput} />
								</div>
								<div className="col-4">
									<TextField name="apellidoM" label="Apellido Materno" type="text" value={profileData.apellidoM} onChange={handleInput} />
								</div>
								<div className="col-6">
									<TextField name="celular" label="Celular" type="text" value={profileData.celular} onChange={handleInput} />
								</div>
								<div className="col-6">
									<TextField name="telefono" label="Telefono" type="text" value={profileData.telefono} onChange={handleInput} />
								</div>
								<div className="col-6">
									<TextField name="email" label="email" type="text" value={profileData.email} onChange={handleInput} />
								</div>
								<div className="col-6">
								<label>Fecha de Nacimiento</label>
								<p>
								  <input
								    value={profileData.fecha_nacimiento}
								    className="form-control"
								    disabled
								  />
								</p>
								</div>
								<div className="col-6">
								<label>Tipo Socio</label>
								<p>
								  <input
								    value={info.tipo_socio}
								    className="form-control"
								    disabled
								  />
								</p>
								</div>
								<div className="col-6">
								<label>País</label>
								<p>
								  <input value={info.pais} className="form-control" disabled />
								</p>
								</div>
								<div className="col-12">
									<Row>
								        <Col xs={24} sm={24} md={24}>
								          <Form.ControlLabel>Género*</Form.ControlLabel>
								          <Form.Group controlId="genero">
								            <Form.Control
								              name="genero"
								              accepter={generoSelector}
								              placeholder="Seleccionar Género"
								              value={genero}
								              defaultValue={genero}
								              onChange={ (e) => setGenero(e) }
								            />
								          </Form.Group>
								        </Col>
								      </Row>
								      <Row>
								        <Col xs={24} sm={12} md={12} className="mb-3">
								          <Form.ControlLabel>Región*</Form.ControlLabel>
								          <Form.Group controlId="region">
								            <Form.Control
								              defaultValue={region}
								              value={region}
								              name="region"
								              accepter={regionSelector}
								              placeholder="Seleccionar Región"
								              onChange={(e) => {
								                getComumunes(e);
								              }}
								            />
								          </Form.Group>
								        </Col>
								        <Col xs={24} sm={12} md={12} className="mb-3">
								          <Form.ControlLabel>Comuna*</Form.ControlLabel>
								          <Form.Group controlId="comuna">
								            <Form.Control
								              defaultValue={comuna}
								              value={comuna}
								              defaultChecked={comuna}
								              name="comuna"
								              accepter={comunaSelector}
								              onChange={ (e) => setComuna(e) }
								              placeholder="Seleccionar Comuna"
								            />
								          </Form.Group>
								        </Col>
								    </Row>
								</div>
								<div className="col-4">
								<TextField name="direccion" label="Dirección" type="text" value={profileData.direccion ?? ''} onChange={handleInput} />
								</div>
								<div className="col-4">
								<TextField name="numero" label="Número" type="text" value={profileData.numero ?? ''} onChange={handleInput} />
								</div>
								<div className="col-4">
									<TextField name="dpto" label="Dpto/Casa" type="text" value={profileData.dpto ?? ''} onChange={handleInput} />
								</div>
			        		</>
			        	}
					</div>
					<div className="row">
					    <div className="col-6">
					    	<button className="btn btn-primary" disabled={loaderUpdateProfile} onClick={handleSubmit}>
					    		Guardar cambios { loaderUpdateProfile && <Spinner animation="grow" size="sm" /> }
					    	</button>
					    </div>
					</div>
					<hr />
					<PasswordUpdater />
				</div>
        	</Form>

        </Layout>
    );
};

export default MyProfile;
