import { useState, useEffect } from 'react';
import * as Api from "../../api";

const usePoints = () => {
    useEffect ( () => { 
        getUserPoints();
    }, []);

    const [userPoints, setUserPoints] = useState(0);

    const getUserPoints = async () => {
        const { data:points } = await Api.getPoints();
        setUserPoints(points);
    }

    return {    
        points: userPoints
    };
}

export default usePoints;