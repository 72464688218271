import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import LayoutFull from "../../Components/Layout/LayoutFull";
import axios from "axios";
import { Message, toaster } from "rsuite";
import LocalStorage from "./../../Service/LocalStorage";
import * as Api from "../../api";
import ProfileCard from "./ProfileCard";
import ProfilePictureForm from "./ProfilePictureForm";

const ProfilePictureChanger = () => {
	
	const info = LocalStorage?.getInformations().data;
	const history = useNavigate();
	const [file, setFile] = useState('');
	const handleFile = (e) => {
		setFile(e.target.files[0]);
	};
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({
    	profile_picture: '', 
    });
    const [hasError, setHasError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [profilePictureUrl, setProfilePictureUrl] = useState('https://mi.csdcolocolo.cl/img/user.png');
    const [updateProfilePicture, setUpdateProfilePicture] = useState(false);

	const handleUpload = async (e) => {
		const formData = new FormData();
		formData.append("profile_picture", file);

		setLoading(true);
		const res = await axios({
			url: `${process.env.REACT_APP_URL_API}socios/profile/upload-picture`,
			method: "POST",
			headers: {
			Authorization: `Bearer ${LocalStorage.getToken().token}`,
			Accept: "application/json",
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		}).then ( res => {
			const url = res.data.url;
			if (url !== "") {

				toaster.push(
					<Message type="success">
						Se subió correctamente su foto de perfil.
					</Message>
				);

				setProfilePictureUrl(url);
				setSuccessMessage('Foto actualizada exitosamente');
				setErrors({profile_picture: ''});
				setHasError(false);
				setFile('');
			}
		}).catch ( err => {
			const validation = err.response.data.errors;

			setSuccessMessage('');
			setErrors({ profile_picture: validation.profile_picture[0] });
			setHasError(true);
		});

		await updateUserInfo();

		setLoading(false);

		setTimeout( () => {
			window.location.reload();
		}, 2000);

	};
	
	const updateUserInfo = async () => {
	  	const response = await Api.getInfoSocio();
	  	LocalStorage.setInformations(response.data);
	}

	const updateCredential = () => setUpdateProfilePicture(true);

	useEffect( () => {
		setProfilePictureUrl(`${process.env.REACT_APP_URL_S3}${info.imagen}`);
	}, [])
	return (
		<>
			<div className="mt-3">
				<h3>Foto de perfil</h3>
				{
					updateProfilePicture ? 
					
					<ProfilePictureForm 
						profilePictureUrl={profilePictureUrl} 
						handleFile={handleFile}
						errors={errors}
						handleUpload={handleUpload}
						successMessage={successMessage} 
						hasError={hasError} 
						loading={loading} /> :

					<ProfileCard profilePictureUrl={profilePictureUrl} />
				}
					
				{
					!updateProfilePicture && (
						<div className="row">
							<div className="col-md-6">
								<button type="button" className="btn btn-primary" onClick={updateCredential}>
									Actualizar fotografía
								</button>
							</div>
						</div>
					)
				}
				<hr />
			</div>
		</>
	);
};

export default ProfilePictureChanger;
