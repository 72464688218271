import React, { useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import * as Api from "../../api";
import { formatNumeric, getEdadCuota, getMonto } from "../../Service/Utils";
import ReactHtmlParser from "html-react-parser";
import LayoutFull from "../../Components/Layout/LayoutFull";
import LocalStorage from "./../../Service/LocalStorage";
import { Radio, RadioGroup, Message, Panel, Paragraph, toaster, Notification } from 'rsuite';
import CreditCard from "../../Components/Payment/CreditCard";

const ValidateIncorporacion = () => {
  
  const [paymentMethod, setPaymentMethod] = useState('');
  const [active, setActive] = useState(true);
  const [urlParams, setUrlParams] = useState();

  const [hasOneclickSub, setHasOneclickSub] = useState(false);
  const [oneclickSub, setOneclickSub] = useState({});
  const [errorOneClickPayment, setErrorOneClickPayment] = useState('');

  const getAmount = () => {
    return getMonto(
      getEdadCuota(LocalStorage.getInformations().data.fecha_nacimiento),
      LocalStorage.getInformations().data.region
    );
  };
  const [inputs, setInputs] = useState({
    valor: getAmount().cuota,
    min_cuota: getAmount().minnumerocuota,
    numero_cuotas: 0,
    buyOrder: 123456789,
    tipo_pago: 'incorporacion',
    metodo_pago: ''
  });
  const [url, setUrl] = useState();
  const [ws_token, setToken] = useState('');

  const handleRadio = (name, value) => {
    console.log("Radio: " + value);
    setPaymentMethod(value);

    if (value == 'oneclick') {
      setShowOneClickInfo(true);
    } else {
      setShowOneClickInfo(false);
    }

    setInputs({
      ...inputs,
      [name]: paymentMethod
    });
  }

  const handleInput = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: Number.isInteger(e.target.value) ? parseInt(e.target.value) : e.target.value,
    });
  };

  const [showOneClickInfo, setShowOneClickInfo] = useState(false);

  const handlePay = async (e) => {

    setActive(false);

    if (paymentMethod == 'oneclick' && hasOneclickSub) {
      const res = await Api.PayCuotaNormalOneClick(inputs);
      await updateUserInfo();

      if (res.status) {
        window.location.href = `/payments/final?id_compra=${res.id_compra}`;
      } else {
      	setErrorOneClickPayment(res.data);
      	setActive(true);
      }
      console.log(res);
      return;
    }


    let data;

    if (paymentMethod == 'oneclick') {
      data = await Api.SubscribeOneClick(inputs);
    } else {
      data = await Api.PayCuotaNormal(inputs); 
      await updateUserInfo();
      console.log(data);
    }

    setUrl(data.url);
    setToken(data.ws_token);
    LocalStorage.setStatus(1);
    document.getElementById("form_webpay").submit();
  }

  const updateUserInfo = async () => {
      const response = await Api.getInfoSocio();
      LocalStorage.setInformations(response.data);
  }

  function OptionSelect() {
    let html = "<option value='0'>Seleccione número de cuotas</option>";
    let min = inputs.min_cuota;
    for (let step = min; step < 25; step++) {
      html += renderToString(<option value={step}>{step}</option>);
    }
    return ReactHtmlParser(renderToString(html));
  }

  const CreditCardSubscription = (props) => (
    <Panel {...props} bordered header={oneclickSub.card_type}>
      { oneclickSub.card_number }
    </Panel>
  );

  const onDelete = () => {
    setShowOneClickInfo(true);
    setHasOneclickSub(false);
    setOneclickSub(null);
  }

  useEffect(() => {

    var url = new URL(window.location);
    const pmethod = url.searchParams.get('payment_method');
    const status = url.searchParams.get('status_oneclick');
    setPaymentMethod(pmethod);
    setShowOneClickInfo(true);
    setUrlParams({payment_method: pmethod, status: status});

    const getOneclickSubscription = async () => {
      const res = await Api.getOneclickSubscription();
      if (res.status && res.data.user) {
        setHasOneclickSub(true);
        setOneclickSub(res.data);
      }
      console.log(res);
    }

    getOneclickSubscription();
    console.log(paymentMethod);
  }, [])

  //optimizar, convertir en componentes para poder reutilizar con la otra ventana de pago
  return (
    <LayoutFull>
      <form name="form_webpay" action={url} method="POST" id="form_webpay">
        {
          inputs.metodo_pago == 'webpay' ? 
          <>
            <input type="hidden" name="token_ws" value={ws_token} />
          </> : <>
            <input type="hidden" name="TBK_TOKEN" value={ws_token} />
          </>
        }
      </form>
      <Container className="my-4">
        {

          urlParams?.status && hasOneclickSub ? 
          <>
            <Row>
              <Col xs={12} md={12}>
                  <Message showIcon type="success">
                    <h6>Tu tarjeta ha sido inscrita exitosamente.</h6>
                    CSD Colo-Colo no almacena los datos privados de tu tarjeta. Dicha información solo será almacenada por Transbank de forma segura.<br />
                  </Message>
              </Col>
            </Row>
          </> : <></>
        }
        <Row>
          <Col xs={12} md={7}>
            <h2 className="title">
              <strong>Pago de cuotas</strong>
            </h2>
            <p>Valor cuota: ${formatNumeric(inputs.valor)}</p>
            <p>Número de cuotas a pagar</p>
            <select
              onChange={handleInput}
              name="numero_cuotas"
              className="form-control"
            >
              {ReactHtmlParser(OptionSelect())}
            </select>
          </Col>
          <Col xs={12} md={5}>
            <table className="table border">
              <tbody>
                <tr>
                  <td>Cuotas Seleccionadas</td>
                  <td align="right">{inputs.numero_cuotas}</td>
                </tr>
                <tr className="semi-bold">
                  <td>Total Final</td>
                  <td align="right">
                    $
                    {formatNumeric(inputs.valor * parseInt(inputs.numero_cuotas))}
                  </td>
                </tr>
                <tr className="semi-bold">
                  <td>Metodo de pago</td>
                </tr>
                <tr>
                  <td>
                    <RadioGroup name="metodo_pago" inline value={paymentMethod} onChange={value => { handleRadio ('metodo_pago', value)}}>
                      <Radio value="webpay">Webpay</Radio>
                      <Radio value="oneclick">OneClick</Radio>
                    </RadioGroup>
                  </td>
                </tr>
                { paymentMethod == 'oneclick' && showOneClickInfo && hasOneclickSub ? 
                  <>
                    <tr>
                      <td>
                        <CreditCard subscription={oneclickSub} onDelete={onDelete} />
                      </td>
                    </tr>
                  </> : 
                  <></>
                }
                { paymentMethod == 'oneclick' && showOneClickInfo && !hasOneclickSub ? 
                  <>
                    <tr>
                      <td>
                        <Message showIcon type="success" header="OneClick">
                          <ul>
                            <li>Para utilizar este medio de pago, debes inscribir una tarjeta de débito o crédito, trámite que deberás realizar una sola vez. </li>
                            <li>Esto es lo mismo que ingresar los datos de tu tarjeta cuando vas a pagar mensualmente tus cuotas, con la diferencia de que dicha información quedará registrada, y podrás utilizar dicha tarjeta cada vez que lo desees, sin necesidad de ingresar los datos cada vez. </li>
                            <li>CSyD Colo-Colo no almacena los datos privados de tu tarjeta. Dicha información solo será almacenada por Transbank de forma segura. </li>
                            <li>No se realizará ningún cobro no autorizado a tu tarjeta.</li>
                          </ul>
                        </Message>
                      </td>
                    </tr>
                  </> : ''
                }
              </tbody>
            </table>
            <div className="text-center">
            	{
            		errorOneClickPayment && (
        			
        			<>
            			<span>{errorOneClickPayment}</span>
            			<br />
        			</>
            	)}
            	
              {active ? (
                <button
                  onClick={(e) => {
                    handlePay(e)
                  }}
                  className="btn bg-red"
                  disabled={!inputs.numero_cuotas > 0}
                >
                  { paymentMethod == 'webpay' || hasOneclickSub ? 'Pagar' : 'Inscribir Trajeta' }
                </button>
              ) : (
                <>
                  <Spinner animation="grow" className="mt-3" />
                  <p className="semi-bold">Redireccionando...</p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </LayoutFull>
  );
};

export default ValidateIncorporacion;
